define("@coachlogix/cl-ui/motions/wait", ["exports", "ember-animated"], function (_exports, _emberAnimated) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = wait;
  function wait(sprite, opts) {
    return new Wait(sprite, opts).run();
  }
  class Wait extends _emberAnimated.Motion {
    *animate() {
      yield (0, _emberAnimated.wait)(this.duration);
    }
  }
});