define("@coachlogix/cl-ui/components/cl-animated-value/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "Om/Q6z1P",
    "block": "{\"symbols\":[\"current\",\"@containerClass\",\"&default\",\"&attrs\",\"@value\"],\"statements\":[[8,\"animated-container\",[[24,0,\"cl-overflow-hidden\"],[17,4]],[[\"@motion\"],[[32,0,[\"containerMotion\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,0],[[32,5]],[[\"rules\",\"duration\"],[[32,0,[\"rules\"]],[32,0,[\"duration\"]]]],[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[15,0,[31,[\"cl-h-full cl-w-full \",[32,2]]]],[12],[2,\"\\n      \"],[18,3,[[32,1]]],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"animated-value\"]}",
    "meta": {
      "moduleName": "@coachlogix/cl-ui/components/cl-animated-value/template.hbs"
    }
  });
  _exports.default = _default;
});