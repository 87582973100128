define("@coachlogix/cl-ui/helpers/get-element-by-id", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.getElementById = getElementById;
  function getElementById([id]) {
    return document.getElementById(id);
  }
  var _default = Ember.Helper.helper(getElementById);
  _exports.default = _default;
});