define("@coachlogix/cl-ui/components/cl-dropzone/component", ["exports", "@ember-decorators/component", "attr-accept", "@coachlogix/cl-ui/components/cl-dropzone/template"], function (_exports, _component, _attrAccept, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _class, _class2, _descriptor, _temp;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  // Firefox versions prior to 53 return a bogus MIME type for every file drag, so dragovers with
  // that MIME type will always be accepted
  function fileAccepted(file, accept) {
    return file.type === 'application/x-moz-file' || (0, _attrAccept.default)(file, accept);
  }
  let ClDropzone = (_dec = (0, _component.tagName)(''), _dec2 = (0, _component.layout)(_template.default), _dec3 = Ember._tracked, _dec4 = Ember._action, _dec5 = Ember._action, _dec6 = Ember._action, _dec7 = Ember._action, _dec(_class = _dec2(_class = (_class2 = (_temp = class ClDropzone extends Ember.Component {
    constructor(...args) {
      super(...args);
      _defineProperty(this, "uniqueId", `cl-dropzone-${Ember.guidFor(this)}`);
      _initializerDefineProperty(this, "isDragging", _descriptor, this);
      _defineProperty(this, "showInput", true);
    }
    handleDrop(ev) {
      ev.preventDefault();
      let files = [];
      if (ev.dataTransfer.files) {
        // Use the interface DataTransfer to access the files
        for (let file of ev.dataTransfer.files) {
          if (fileAccepted(file, this.accept)) {
            files.push(file);
          }
        }
      } else if (ev.dataTransfer.items && !this.accept) {
        // Use the interface DataTransferItemList to access the files
        for (let item of ev.dataTransfer.items) {
          // If the dropped items are not files, reject them
          if (item.kind === 'file') {
            files.push(item.getAsFile());
          }
        }
      }
      if (!this.multiple && files.length > 1) {
        files = files.slice(0, 1);
      }
      this.isDragging = false;
      this.onChange?.(files);
    }
    handleDragEnter() {
      this.isDragging = true;
    }
    handleDragLeave(ev) {
      if (ev.currentTarget.contains(ev.relatedTarget)) {
        return;
      }
      this.isDragging = false;
    }
    handleChange(ev) {
      let files = [];
      if (ev.target.files && ev.target.files.length) {
        files = ev.target.files;
      }
      this.onChange?.(files);
    }
  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "isDragging", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _applyDecoratedDescriptor(_class2.prototype, "handleDrop", [_dec4], Object.getOwnPropertyDescriptor(_class2.prototype, "handleDrop"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "handleDragEnter", [_dec5], Object.getOwnPropertyDescriptor(_class2.prototype, "handleDragEnter"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "handleDragLeave", [_dec6], Object.getOwnPropertyDescriptor(_class2.prototype, "handleDragLeave"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "handleChange", [_dec7], Object.getOwnPropertyDescriptor(_class2.prototype, "handleChange"), _class2.prototype)), _class2)) || _class) || _class);
  var _default = ClDropzone;
  _exports.default = _default;
});