define("@coachlogix/cl-ui/components/cl-company-avatar/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "Pp0Tmo6c",
    "block": "{\"symbols\":[\"@imageUrl\",\"&attrs\",\"&default\"],\"statements\":[[11,\"div\"],[16,0,[31,[[32,0,[\"styleNamespace\"]],\" \",[30,[36,0],[[32,1],\"no-image\"],null]]]],[16,5,[32,0,[\"style\"]]],[24,\"role\",\"img\"],[17,2],[12],[2,\"\\n\"],[6,[37,0],[[32,1]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[8,\"cl-icon\",[],[[\"@icon\",\"@size\"],[\"business\",[32,0,[\"iconSize\"]]]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n  \"],[18,3,null],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"unless\"]}",
    "meta": {
      "moduleName": "@coachlogix/cl-ui/components/cl-company-avatar/template.hbs"
    }
  });
  _exports.default = _default;
});