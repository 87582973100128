define("@coachlogix/cl-ui/components/cl-list/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "Fzcy+Fy/",
    "block": "{\"symbols\":[\"Element\",\"&attrs\",\"&default\",\"@htmlTag\"],\"statements\":[[6,[37,4],[[30,[36,0],[[30,[36,3],[[30,[36,2],[[32,4],\"ul\"],null]],null]],[[\"tagName\"],[[30,[36,2],[[32,4],\"ul\"],null]]]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[8,[32,1],[[24,0,\"cl-flex cl-flex-col cl-m-0 cl-p-0 cl-list-none\"],[17,2]],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[18,3,[[30,[36,1],null,[[\"item\",\"subheader\"],[[30,[36,0],[\"cl-list/item\"],[[\"bordered\",\"dense\"],[[32,0,[\"bordered\"]],[32,0,[\"dense\"]]]]],[30,[36,0],[\"cl-list/subheader\"],null]]]]]],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"component\",\"hash\",\"or\",\"-element\",\"let\"]}",
    "meta": {
      "moduleName": "@coachlogix/cl-ui/components/cl-list/template.hbs"
    }
  });
  _exports.default = _default;
});