define("@coachlogix/cl-ui/components/cl-dropdown/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "5ly1TBdf",
    "block": "{\"symbols\":[\"dd\",\"@disabled\",\"@calculatePosition\",\"@horizontalPosition\",\"@verticalPosition\",\"@matchTriggerWidth\",\"@preventScroll\",\"@renderInPlace\",\"@initiallyOpened\",\"@onOpen\",\"@onClose\",\"&attrs\",\"&default\"],\"statements\":[[8,\"basic-dropdown\",[[16,0,[32,0,[\"styleNamespace\"]]],[17,12]],[[\"@disabled\",\"@calculatePosition\",\"@horizontalPosition\",\"@verticalPosition\",\"@matchTriggerWidth\",\"@preventScroll\",\"@renderInPlace\",\"@initiallyOpened\",\"@onOpen\",\"@onClose\",\"@destination\"],[[32,2],[30,[36,0],[[32,3],[32,0,[\"defaultCalculatePosition\"]]],null],[30,[36,0],[[32,4],\"auto\"],null],[30,[36,0],[[32,5],\"auto\"],null],[32,6],[32,7],[32,8],[32,9],[32,10],[32,11],\"cl-wormhole\"]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[18,13,[[30,[36,4],null,[[\"trigger\",\"content\",\"actions\",\"disabled\",\"isOpen\",\"uniqueId\"],[[30,[36,3],[\"cl-dropdown/trigger\"],[[\"ebdContent\",\"defaultClass\",\"onMouseDown\",\"onMouseEnter\",\"onMouseLeave\"],[[32,1,[\"Trigger\"]],[30,[36,2],[[32,0,[\"styleNamespace\"]],\"-trigger\"],null],[32,0,[\"prevent\"]],[30,[36,1],[[32,0,[\"open\"]],[32,1]],null],[30,[36,1],[[32,0,[\"closeLater\"]],[32,1]],null]]]],[30,[36,3],[\"cl-dropdown/content\"],[[\"ebdContent\",\"defaultClass\",\"onMouseEnter\",\"onMouseLeave\"],[[32,1,[\"Content\"]],[30,[36,2],[[32,0,[\"styleNamespace\"]],\"-content\"],null],[30,[36,1],[[32,0,[\"open\"]],[32,1]],null],[30,[36,1],[[32,0,[\"closeLater\"]],[32,1]],null]]]],[32,1,[\"actions\"]],[32,1,[\"disabled\"]],[32,1,[\"isOpen\"]],[32,1,[\"uniqueId\"]]]]]]],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"or\",\"fn\",\"concat\",\"component\",\"hash\"]}",
    "meta": {
      "moduleName": "@coachlogix/cl-ui/components/cl-dropdown/template.hbs"
    }
  });
  _exports.default = _default;
});