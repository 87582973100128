define("@coachlogix/cl-ui/components/cl-dialog/actions/component", ["exports", "@ember-decorators/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class;
  let ClDialogActions = (_dec = (0, _component.classNames)('cl-px-3', 'cl-py-2', 'cl-flex', 'cl-items-center', 'cl-justify-end'), _dec(_class = class ClDialogActions extends Ember.Component {}) || _class);
  var _default = ClDialogActions;
  _exports.default = _default;
});