define("@coachlogix/cl-ui/components/cl-calendar/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "Eh4y1AUi",
    "block": "{\"symbols\":[\"CalendarComponent\",\"calendar\",\"currentCenter\",\"currentCenter\",\"@minDate\",\"@maxDate\",\"@mini\",\"@center\",\"@selected\",\"&attrs\",\"@range\"],\"statements\":[[6,[37,5],[[30,[36,4],[[30,[36,1],[[32,11],\"power-calendar-range\",\"power-calendar\"],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[8,[32,1],[[16,0,[31,[[32,0,[\"styleNamespace\"]],\" \",[30,[36,1],[[32,7],\"mini\"],null]]]],[17,10]],[[\"@center\",\"@onCenterChange\",\"@selected\",\"@onSelect\"],[[32,8],[32,0,[\"handleOnCenterChange\"]],[32,9],[32,0,[\"handleOnSelect\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"cl-calendar/nav\",[],[[\"@mode\",\"@onModeChange\",\"@calendar\",\"@mini\"],[[32,0,[\"mode\"]],[30,[36,2],[[30,[36,3],[[32,0,[\"mode\"]]],null]],null],[32,2],[32,7]]],null],[2,\"\\n\\n\"],[6,[37,1],[[30,[36,0],[[32,0,[\"mode\"]],\"decade\"],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"      \"],[8,\"cl-animated-value\",[],[[\"@value\"],[[32,2,[\"center\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[8,\"cl-calendar/decade\",[],[[\"@onYearSelected\",\"@calendar\",\"@center\"],[[30,[36,2],[[32,0,[\"handleYearSelected\"]],[32,2,[\"center\"]]],null],[32,2],[32,4]]],null],[2,\"\\n      \"]],\"parameters\":[4]}]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,1],[[30,[36,0],[[32,0,[\"mode\"]],\"month\"],null]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[8,\"cl-animated-value\",[],[[\"@value\"],[[32,2,[\"center\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[8,[32,2,[\"Days\"]],[],[[\"@center\",\"@minDate\",\"@maxDate\"],[[32,3],[32,5],[32,6]]],null],[2,\"\\n      \"]],\"parameters\":[3]}]]],[2,\"\\n    \"]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[2,\"  \"]],\"parameters\":[2]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"eq\",\"if\",\"fn\",\"mut\",\"component\",\"let\"]}",
    "meta": {
      "moduleName": "@coachlogix/cl-ui/components/cl-calendar/template.hbs"
    }
  });
  _exports.default = _default;
});