define("@coachlogix/cl-ui/components/cl-list/item/component", ["exports", "@ember-decorators/component", "@coachlogix/cl-ui/components/cl-list/item/template"], function (_exports, _component, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _class, _class2;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  let ClListItem = (_dec = (0, _component.tagName)(''), _dec2 = (0, _component.layout)(_template.default), _dec3 = Ember._action, _dec(_class = _dec2(_class = (_class2 = class ClListItem extends Ember.Component {
    didClick() {
      if (this.onClick && !this.disabled && !this.isDragging) {
        this.onClick(...arguments);
      }
    }
  }, (_applyDecoratedDescriptor(_class2.prototype, "didClick", [_dec3], Object.getOwnPropertyDescriptor(_class2.prototype, "didClick"), _class2.prototype)), _class2)) || _class) || _class);
  var _default = ClListItem;
  _exports.default = _default;
});