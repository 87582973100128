define("ember-animated-tools/templates/components/time-control", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "F9sBsuQn",
    "block": "{\"symbols\":[\"tickMark\"],\"statements\":[[10,\"div\"],[12],[2,\"\\n  \"],[10,\"label\"],[12],[2,\"Speed \"],[1,[30,[36,3],[[35,2]],null]],[2,\"%\"],[13],[2,\"\\n  \"],[10,\"div\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"eat-time-control--tick-marks\"],[12],[2,\"\\n\"],[6,[37,6],[[30,[36,5],[[30,[36,5],[[35,4]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[11,\"div\"],[16,5,[32,1,[\"position\"]]],[4,[38,1],[[32,0],[35,0],[32,1]],null],[12],[2,\"\\n        \"],[10,\"span\"],[12],[1,[32,1,[\"text\"]]],[13],[2,\"\\n      \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"    \"],[13],[2,\"\\n    \"],[10,\"input\"],[14,0,\"eat-time-control--slider\"],[15,2,[34,7]],[14,\"min\",\"0\"],[14,\"max\",\"200\"],[14,\"step\",\"1\"],[15,\"oninput\",[30,[36,1],[[32,0],[35,8]],null]],[14,4,\"range\"],[12],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"tickMarkChosen\",\"action\",\"speedPercent\",\"-eat-rounded\",\"tickMarks\",\"-track-array\",\"each\",\"logSpeed\",\"updateLogSpeed\"]}",
    "meta": {
      "moduleName": "ember-animated-tools/templates/components/time-control.hbs"
    }
  });
  _exports.default = _default;
});