define("@coachlogix/cl-ui/components/cl-expandable/body/component", ["exports", "@ember-decorators/component", "@coachlogix/cl-ui/motions/wait", "@coachlogix/cl-ui/components/cl-expandable/body/template"], function (_exports, _component, _wait, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _class;
  let ClExpandableBody = (_dec = (0, _component.tagName)(''), _dec2 = (0, _component.layout)(_template.default), _dec(_class = _dec2(_class = class ClExpandableBody extends Ember.Component {
    /* eslint-disable-next-line */
    *transition({
      removedSprites
    }) {
      removedSprites.forEach(sprite => {
        (0, _wait.default)(sprite);
      });
    }
  }) || _class) || _class);
  var _default = ClExpandableBody;
  _exports.default = _default;
});