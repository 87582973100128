define("@coachlogix/cl-ui/components/cl-empty-state/component", ["exports", "@ember-decorators/component", "@coachlogix/cl-ui/components/cl-empty-state/template"], function (_exports, _component, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class;
  let ClEmptyState = (_dec = (0, _component.layout)(_template.default), _dec(_class = class ClEmptyState extends Ember.Component {}) || _class);
  var _default = ClEmptyState;
  _exports.default = _default;
});