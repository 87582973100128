define("@coachlogix/cl-ui/components/cl-chart-stacked/bar/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "l42lV2j7",
    "block": "{\"symbols\":[\"&default\",\"@color\",\"&attrs\"],\"statements\":[[11,\"div\"],[16,0,[31,[[30,[36,3],[[32,2],[30,[36,2],[\"cl-bg-\",[32,0,[\"variant\"]]],null]],null],\" cl-transition-all cl-ease-out-exp cl-duration-500\"]]],[16,5,[32,0,[\"style\"]]],[17,3],[12],[2,\"\\n\"],[6,[37,4],[[27,[32,1]]],null,[[\"default\"],[{\"statements\":[[6,[37,1],[[30,[36,0],[[32,0,[\"labelEl\"]]],null]],[[\"guid\",\"insertBefore\"],[\"%cursor:0%\",null]],[[\"default\"],[{\"statements\":[[2,\"      \"],[18,1,null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"    \"],[8,\"cl-tooltip\",[],[[\"@position\",\"@followCursor\"],[\"top\",true]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[18,1,null],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]],[13]],\"hasEval\":false,\"upvars\":[\"-clear-element\",\"in-element\",\"concat\",\"unless\",\"if\"]}",
    "meta": {
      "moduleName": "@coachlogix/cl-ui/components/cl-chart-stacked/bar/template.hbs"
    }
  });
  _exports.default = _default;
});