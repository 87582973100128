define("@coachlogix/cl-ui/components/cl-input/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "6dxvp6LO",
    "block": "{\"symbols\":[\"@value\",\"&attrs\",\"@label\",\"@icon\",\"@containerClass\",\"@textarea\",\"&default\"],\"statements\":[[10,\"div\"],[15,0,[31,[\"cl-flex cl-relative \",[32,0,[\"styleNamespace\"]],\" \",[32,0,[\"inputStyleClass\"]],\" \",[30,[36,4],[[32,4],\"has-icon\"],null],\" \",[30,[36,4],[[32,3],\"has-label\"],null],\" \",[32,5]]]],[12],[2,\"\\n\"],[6,[37,4],[[32,4]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[14,0,\"icon-container cl-flex cl-items-center\"],[12],[2,\"\\n      \"],[8,\"cl-icon\",[[16,\"size\",24]],[[\"@icon\"],[[32,4]]],null],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n  \"],[10,\"div\"],[14,0,\"input-container cl-flex-1 cl-flex cl-flex-col cl-relative cl-w-full\"],[12],[2,\"\\n\"],[6,[37,4],[[32,3]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[10,\"label\"],[15,\"for\",[32,0,[\"uniqueId\"]]],[14,0,\"cl-title-7\"],[12],[1,[32,3]],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[6,[37,4],[[32,6]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"      \"],[11,\"textarea\"],[16,1,[32,0,[\"uniqueId\"]]],[16,2,[32,1]],[16,0,[31,[\"cl-resize-none \",[30,[36,0],[[32,0,[\"editable\"]],\"readonly\"],null]]]],[16,\"readonly\",[30,[36,1],[[32,0,[\"editable\"]]],null]],[17,2],[4,[38,2],[\"input\",[32,0,[\"handleInput\"]]],null],[4,[38,3],[[32,0,[\"setupAutosize\"]]],null],[12],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"      \"],[11,\"input\"],[16,1,[32,0,[\"uniqueId\"]]],[16,2,[32,1]],[16,0,[30,[36,0],[[32,0,[\"editable\"]],\"readonly\"],null]],[16,\"readonly\",[30,[36,1],[[32,0,[\"editable\"]]],null]],[17,2],[4,[38,2],[\"input\",[32,0,[\"handleInput\"]]],null],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n    \"],[18,7,null],[2,\"\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"unless\",\"not\",\"on\",\"did-insert\",\"if\"]}",
    "meta": {
      "moduleName": "@coachlogix/cl-ui/components/cl-input/template.hbs"
    }
  });
  _exports.default = _default;
});