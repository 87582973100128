define("@coachlogix/cl-ui/services/cl-custom-properties", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const docEl = window && window.document ? window.document.documentElement : null;
  class ClCustomProperties extends Ember.Service {
    getVal({
      element = docEl,
      variableName
    }) {
      if (!element) {
        Ember.debug('No element provided');
        return false;
      }
      if (cssVariablesSupported()) {
        if (variableName) {
          return getComputedStyle(element).getPropertyValue(variableName).trim();
        } else {
          Ember.debug('No variable name provided');
        }
      } else {
        Ember.debug('CSS variables are not supported');
        Ember.debug(`Tried to get custom property on <${element}> with name ${variableName}`);
      }
    }
    setVal({
      element = docEl,
      variableName,
      variableValue
    }) {
      if (!element) {
        Ember.debug('No element provided');
        return false;
      }
      if (cssVariablesSupported()) {
        if (!!variableName && !!variableValue) {
          return element.style.setProperty(variableName, variableValue);
        } else {
          Ember.debug('Needs variable name and value to perform setVal');
        }
      } else {
        Ember.debug('CSS variables are not supported');
        Ember.debug(`Tried to set custom property on <${element}> with name ${variableName} and value ${variableValue}`);
      }
    }
    removeVal({
      element = docEl,
      variableName
    }) {
      if (!element) {
        Ember.debug('No element provided');
        return false;
      }
      if (cssVariablesSupported()) {
        if (variableName) {
          return element.style.removeProperty(variableName);
        } else {
          Ember.debug('No variable name provided');
        }
      } else {
        Ember.debug('CSS variables are not supported');
        Ember.debug(`Tried to remove custom property on <${element}> with name ${variableName}`);
      }
    }
    supported() {
      return cssVariablesSupported();
    }
  }
  _exports.default = ClCustomProperties;
  function cssVariablesSupported() {
    // Relies on the CSS suports API in the browser
    return window && window.CSS && window.CSS.supports && window.CSS.supports('color', 'var(--fake-var)');
  }
});