define("@coachlogix/cl-ui/components/cl-tooltip/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "9YgHMN25",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[1,[30,[36,0],[[32,0,[\"_parentFinder\"]]],null]],[2,\"\\n\\n\"],[1,[30,[36,1],[[32,0,[\"onDidInsert\"]]],null]],[2,\"\\n\"],[1,[30,[36,2],[[32,0,[\"onWillDestroy\"]]],null]],[2,\"\\n\\n\"],[6,[37,4],[[30,[36,3],[[32,0,[\"tippyElement\"]]],null]],[[\"guid\",\"insertBefore\"],[\"%cursor:0%\",null]],[[\"default\"],[{\"statements\":[[2,\"  \"],[18,1,null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"unbound\",\"did-insert\",\"will-destroy\",\"-clear-element\",\"in-element\"]}",
    "meta": {
      "moduleName": "@coachlogix/cl-ui/components/cl-tooltip/template.hbs"
    }
  });
  _exports.default = _default;
});