define("@coachlogix/cl-ui/components/cl-comments/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "2TKeN7sJ",
    "block": "{\"symbols\":[\"&attrs\",\"&default\"],\"statements\":[[11,\"div\"],[16,0,[31,[\"cl-flex cl-flex-col \",[32,0,[\"styleNamespace\"]]]]],[17,1],[12],[2,\"\\n  \"],[18,2,[[30,[36,1],null,[[\"comment\"],[[30,[36,0],[\"cl-comments/comment\"],[[\"class\"],[\"cl-mb-4 cl-comment\"]]]]]]]],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"component\",\"hash\"]}",
    "meta": {
      "moduleName": "@coachlogix/cl-ui/components/cl-comments/template.hbs"
    }
  });
  _exports.default = _default;
});