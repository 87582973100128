define("@coachlogix/cl-ui/transitions/cl-move-over", ["exports", "ember-animated/motions/follow", "ember-animated/motions/move", "@coachlogix/cl-ui/easings/ease-exp-out"], function (_exports, _follow, _move, _easeExpOut) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = moveOver;
  _exports.toUp = _exports.toRight = _exports.toLeft = _exports.toDown = void 0;
  const moveOpts = {
    easing: _easeExpOut.default,
    duration: 500
  };

  // copied from https://github.com/ember-animation/ember-animated/blob/master/addon/transitions/move-over.js
  // changed the options provided to the Move motion to include the easing and duration

  const toLeft = moveOver.bind(null, 'x', -1);
  _exports.toLeft = toLeft;
  const toRight = moveOver.bind(null, 'x', 1);
  _exports.toRight = toRight;
  const toUp = moveOver.bind(null, 'y', -1);
  _exports.toUp = toUp;
  const toDown = moveOver.bind(null, 'y', 1);
  _exports.toDown = toDown;
  function normalize(dimension, direction) {
    let position;
    let size;
    let startTranslatedBy;
    let endTranslatedBy;
    if (dimension.toLowerCase() === 'x') {
      size = bounds => bounds.width;
      if (direction > 0) {
        position = bounds => bounds.left;
        startTranslatedBy = (sprite, distance) => {
          let offsetY = sprite._offsetSprite ? sprite._offsetSprite.finalBounds.top - sprite._offsetSprite.initialBounds.top : 0;
          sprite.startTranslatedBy(distance, offsetY);
        };
        endTranslatedBy = (sprite, distance) => sprite.endTranslatedBy(distance, 0);
      } else {
        position = bounds => -bounds.right;
        startTranslatedBy = (sprite, distance) => {
          let offsetY = sprite._offsetSprite ? sprite._offsetSprite.finalBounds.top - sprite._offsetSprite.initialBounds.top : 0;
          sprite.startTranslatedBy(-distance, offsetY);
        };
        endTranslatedBy = (sprite, distance) => sprite.endTranslatedBy(-distance, 0);
      }
    } else {
      size = bounds => bounds.height;
      if (direction > 0) {
        position = bounds => bounds.top;
        startTranslatedBy = (sprite, distance) => sprite.startTranslatedBy(0, distance);
        endTranslatedBy = (sprite, distance) => sprite.endTranslatedBy(0, distance);
      } else {
        position = bounds => -bounds.bottom;
        startTranslatedBy = (sprite, distance) => sprite.startTranslatedBy(0, -distance);
        endTranslatedBy = (sprite, distance) => sprite.endTranslatedBy(0, -distance);
      }
    }
    return {
      position,
      size,
      startTranslatedBy,
      endTranslatedBy
    };
  }

  /* eslint-disable-next-line */
  function* moveOver(dimension, direction, context) {
    let {
      position,
      size,
      startTranslatedBy,
      endTranslatedBy
    } = normalize(dimension, direction);
    let viewport;
    if (context.insertedSprites.length) {
      viewport = context.insertedSprites[0].finalBounds;
    } else if (context.keptSprites.length) {
      viewport = context.keptSprites[0].finalBounds;
    } else {
      // throw new Error('Unimplemented');
    }
    if (context.insertedSprites.length) {
      // Offset is how far out of place we're going to start the inserted sprite.
      let offset = 0;

      // if any leaving sprites still hang outside the viewport to the
      // left, they add to our offset because the new sprite will be to
      // their left.
      context.removedSprites.forEach(sprite => {
        let o = position(viewport) - position(sprite.initialBounds);
        if (o > offset) {
          offset = o;
        }
      });

      // the new sprite's own width adds to our offset because we want its
      // right edge (not left edge) to start touching the leftmost leaving
      // sprite (or viewport if no leaving sprites)
      offset += size(context.insertedSprites[0].finalBounds);
      startTranslatedBy(context.insertedSprites[0], -offset);
      if (context.removedSprites.length > 0) {
        endTranslatedBy(context.removedSprites[0], offset);
        let move = new _move.Move(context.removedSprites[0], moveOpts);
        move.run();
        for (let i = 1; i < context.removedSprites.length; i++) {
          (0, _follow.default)(context.removedSprites[i], {
            source: move
          });
        }
        (0, _follow.default)(context.insertedSprites[0], {
          source: move
        });
      } else {
        new _move.Move(context.insertedSprites[0], moveOpts).run();
      }
    } else if (context.keptSprites.length) {
      let move = new _move.Move(context.keptSprites[0], moveOpts);
      move.run();
      context.removedSprites.forEach(sprite => {
        (0, _follow.default)(sprite, {
          source: move
        });
      });
    } else {
      // throw new Error('Unimplemented2');
    }
  }
});