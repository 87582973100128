define("ember-yeti-table/components/yeti-table/pagination/component", ["exports", "@ember-decorators/component", "ember-yeti-table/components/yeti-table/pagination/template"], function (_exports, _component, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _class2, _descriptor, _descriptor2;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  /**
    Simple pagination controls component that is included to help you get started quickly.
    Yeti Table yields a lot of pagination data, so you shouldn't have a problem
    creating your own pagination controls.
  
    At any rate, this component tries to be as flexible as possible. Some arguments
    are provided to customize how this component behaves.
  
    If you want to render these controls on the table footer, you probably want
    a footer row that always spans all rows. To do that you can use a `colspan` equal
    to the yielded `visibleColumns.length` number. Example:
  
    ```hbs
    <YetiTable @data={{this.data}} @pagination={{true}} as |table|>
      ...
      <table.tfoot as |foot|>
        <foot.row as |row|>
          <row.cell colspan={{table.visibleColumns.length}}>
            <table.pagination/>
          </row.cell>
        </foot.row>
      </table.tfoot>
    </YetiTable>
    ```
  */
  let Pagination = (_dec = (0, _component.tagName)(''), _dec2 = (0, _component.layout)(_template.default), _dec3 = Ember.computed.or('paginationData.isFirstPage', 'disabled'), _dec4 = Ember.computed.or('paginationData.isLastPage', 'disabled'), _dec5 = Ember._action, _dec(_class = _dec2(_class = (_class2 = class Pagination extends Ember.Component {
    constructor(...args) {
      super(...args);
      _defineProperty(this, "theme", void 0);
      _defineProperty(this, "paginationData", void 0);
      _defineProperty(this, "paginationActions", void 0);
      _defineProperty(this, "disabled", void 0);
      _initializerDefineProperty(this, "shouldDisablePrevious", _descriptor, this);
      _initializerDefineProperty(this, "shouldDisableNext", _descriptor2, this);
      _defineProperty(this, "pageSizes", [10, 15, 20, 25]);
      _defineProperty(this, "showInfo", true);
      _defineProperty(this, "showPageSizeSelector", true);
      _defineProperty(this, "showButtons", true);
    }
    /**
     * Array of page sizes to populate the page size `<select>`.
     * Particularly useful with an array helper, e.g `@pageSizes={{array 10 12 23 50 100}}`
     * Defaults to `[10, 15, 20, 25]`.
     */
    /**
     * Used to show/hide some textual information about the current page. Defaults to `true`.
     */
    /**
     * Used to show/hide the page size selector. Defaults to `true`.
     */
    /**
     * Used to show/hide the previous and next page buttons. Defaults to `true`.
     */
    changePageSize(ev) {
      this.paginationActions.changePageSize(ev.target.value);
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "shouldDisablePrevious", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "shouldDisableNext", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "changePageSize", [_dec5], Object.getOwnPropertyDescriptor(_class2.prototype, "changePageSize"), _class2.prototype)), _class2)) || _class) || _class);
  var _default = Pagination;
  _exports.default = _default;
});