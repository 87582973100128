define("@coachlogix/cl-ui/components/cl-alert/text/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "gaEouRuy",
    "block": "{\"symbols\":[\"@class\",\"&attrs\",\"&default\"],\"statements\":[[11,\"div\"],[16,0,[32,1]],[17,2],[12],[2,\"\\n  \"],[18,3,null],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "@coachlogix/cl-ui/components/cl-alert/text/template.hbs"
    }
  });
  _exports.default = _default;
});