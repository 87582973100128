define("@coachlogix/cl-ui/components/cl-dropdown/trigger/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "MDZzvnh6",
    "block": "{\"symbols\":[\"EbdTrigger\",\"@stopPropagation\",\"&attrs\",\"@onMouseDown\",\"@onMouseEnter\",\"@onMouseLeave\",\"&default\",\"@defaultClass\",\"@ebdContent\"],\"statements\":[[6,[37,2],[[30,[36,1],[[32,9]],[[\"defaultClass\"],[[32,8]]]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[8,[32,1],[[17,3],[4,[38,0],[\"mousedown\",[32,4]],null],[4,[38,0],[\"mouseenter\",[32,5]],null],[4,[38,0],[\"mouseleave\",[32,6]],null]],[[\"@stopPropagation\"],[[32,2]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[18,7,null],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"on\",\"component\",\"let\"]}",
    "meta": {
      "moduleName": "@coachlogix/cl-ui/components/cl-dropdown/trigger/template.hbs"
    }
  });
  _exports.default = _default;
});