define("@coachlogix/cl-ui/components/cl-drawer/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "LzrlFHMK",
    "block": "{\"symbols\":[\"current\",\"&default\",\"&attrs\",\"@open\"],\"statements\":[[8,\"animated-container\",[[24,0,\"cl-flex\"],[17,3]],[[\"@motion\"],[[32,0,[\"containerMotion\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,1],[[32,4]],[[\"use\"],[[32,0,[\"slide\"]]]],[[\"default\"],[{\"statements\":[[6,[37,0],[[32,1]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[18,2,[[32,1]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[1]}]]]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"if\",\"animated-value\"]}",
    "meta": {
      "moduleName": "@coachlogix/cl-ui/components/cl-drawer/template.hbs"
    }
  });
  _exports.default = _default;
});