define("@coachlogix/cl-ui/components/cl-menu/content/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "nZtpTwuw",
    "block": "{\"symbols\":[\"list\",\"&attrs\",\"@ddActions\",\"&default\",\"@dropdownContent\"],\"statements\":[[6,[37,0],[[32,5]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[8,\"cl-list\",[[24,0,\"cl-py-1\"],[17,2]],[[\"@bordered\"],[[32,0,[\"bordered\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[18,4,[[30,[36,1],null,[[\"subheader\",\"item\"],[[32,1,[\"subheader\"]],[30,[36,0],[\"cl-menu/content/item\"],[[\"itemComponent\",\"ddActions\",\"class\"],[[32,1,[\"item\"]],[32,3],\"cl-font-semibold cl-flex-shrink-0\"]]]]]]]],[2,\"\\n  \"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"component\",\"hash\"]}",
    "meta": {
      "moduleName": "@coachlogix/cl-ui/components/cl-menu/content/template.hbs"
    }
  });
  _exports.default = _default;
});