define("@coachlogix/cl-ui/components/cl-date-picker/component", ["exports", "@ember-decorators/component", "moment", "@coachlogix/cl-ui/utils/default-to", "@coachlogix/cl-ui/components/cl-date-picker/template"], function (_exports, _component, _moment, _defaultTo, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _class, _class2, _descriptor, _descriptor2, _temp;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  let ClDatePicker = (_dec = (0, _component.tagName)(''), _dec2 = (0, _component.layout)(_template.default), _dec3 = (0, _defaultTo.default)(true), _dec4 = Ember.inject.service, _dec5 = Ember.computed('selected.{start,end}', 'range', 'formatDateOptions'), _dec6 = Ember._action, _dec(_class = _dec2(_class = (_class2 = (_temp = class ClDatePicker extends Ember.Component {
    constructor(...args) {
      super(...args);
      _defineProperty(this, "closeOnSelect", true);
      _initializerDefineProperty(this, "allowClear", _descriptor, this);
      _initializerDefineProperty(this, "intl", _descriptor2, this);
      _defineProperty(this, "formatDateOptions", {
        day: 'numeric',
        month: 'short',
        year: 'numeric'
      });
      _defineProperty(this, "predefinedRanges", [{
        label: 'Last 7 days',
        range: {
          start: (0, _moment.default)().subtract(6, 'days'),
          end: (0, _moment.default)()
        }
      }, {
        label: 'Last month',
        range: {
          start: (0, _moment.default)().subtract(1, 'month'),
          end: (0, _moment.default)()
        }
      }, {
        label: 'Last 3 months',
        range: {
          start: (0, _moment.default)().subtract(3, 'months'),
          end: (0, _moment.default)()
        }
      }, {
        label: 'Last 6 months',
        range: {
          start: (0, _moment.default)().subtract(6, 'months'),
          end: (0, _moment.default)()
        }
      }, {
        label: 'Last year',
        range: {
          start: (0, _moment.default)().subtract(1, 'year').startOf('year'),
          end: (0, _moment.default)().subtract(1, 'year').endOf('year')
        }
      }, {
        label: 'This year',
        range: {
          start: (0, _moment.default)().startOf('year'),
          end: (0, _moment.default)()
        }
      }]);
    }
    get formattedDate() {
      if (this.range) {
        if (this.selected && this.selected.start && this.selected.end) {
          let start = this.intl.formatDate(this.selected.start, this.formatDateOptions);
          let end = this.intl.formatDate(this.selected.end, this.formatDateOptions);
          return `${start} - ${end}`;
        }
      } else {
        if (this.selected) {
          return this.intl.formatDate(this.selected, this.formatDateOptions);
        }
      }
      return '';
    }
    handleSelect(dd, dayOrRange, e) {
      let value = this.onSelect(dayOrRange, dd, e);
      if (value === false || !this.closeOnSelect) {
        return;
      }
      if (this.range && dayOrRange && dayOrRange.start && !dayOrRange.end) {
        return;
      }
      dd.actions.close();
    }
  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "allowClear", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "intl", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "formattedDate", [_dec5], Object.getOwnPropertyDescriptor(_class2.prototype, "formattedDate"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "handleSelect", [_dec6], Object.getOwnPropertyDescriptor(_class2.prototype, "handleSelect"), _class2.prototype)), _class2)) || _class) || _class);
  var _default = ClDatePicker;
  _exports.default = _default;
});