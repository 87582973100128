define("@coachlogix/cl-ui/components/cl-expandable/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "MCx5qb8I",
    "block": "{\"symbols\":[\"&attrs\",\"&default\"],\"statements\":[[11,\"div\"],[17,1],[12],[2,\"\\n  \"],[18,2,[[30,[36,1],null,[[\"header\",\"body\"],[[30,[36,0],[\"cl-expandable/header\"],null],[30,[36,0],[\"cl-expandable/body\"],[[\"expanded\"],[[32,0,[\"expanded\"]]]]]]]]]],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"component\",\"hash\"]}",
    "meta": {
      "moduleName": "@coachlogix/cl-ui/components/cl-expandable/template.hbs"
    }
  });
  _exports.default = _default;
});