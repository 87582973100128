define("@coachlogix/cl-ui/components/cl-checkbox/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "o+l/7y6R",
    "block": "{\"symbols\":[\"&attrs\",\"&default\"],\"statements\":[[11,\"div\"],[16,0,[31,[[32,0,[\"styleNamespace\"]],\" cl-inline-flex cl-items-center \",[30,[36,0],[[32,0,[\"disabled\"]],\"disabled\"],null]]]],[24,\"role\",\"checkbox\"],[16,\"aria-checked\",[30,[36,0],[[32,0,[\"value\"]],\"true\",\"false\"],null]],[24,\"tabindex\",\"-1\"],[17,1],[4,[38,1],[\"click\",[32,0,[\"handleClick\"]]],null],[4,[38,1],[\"keydown\",[32,0,[\"handleKeydown\"]]],null],[12],[2,\"\\n  \"],[8,\"cl-button\",[[16,\"disabled\",[32,0,[\"disabled\"]]]],[[\"@class\",\"@iconButton\",\"@primary\"],[\"checkbox-button\",true,[30,[36,0],[[32,0,[\"disabled\"]],false,true],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,0],[[32,0,[\"value\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"      \"],[8,\"cl-icon\",[],[[\"@icon\"],[\"check-box\"]],null],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"      \"],[8,\"cl-icon\",[],[[\"@icon\"],[\"check-box-outline-blank\"]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"]],\"parameters\":[]}]]],[2,\"\\n\\n  \"],[10,\"div\"],[12],[2,\"\\n    \"],[18,2,null],[2,\"\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"if\",\"on\"]}",
    "meta": {
      "moduleName": "@coachlogix/cl-ui/components/cl-checkbox/template.hbs"
    }
  });
  _exports.default = _default;
});