define("@coachlogix/cl-ui/components/cl-animated-each/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "I085XNBh",
    "block": "{\"symbols\":[\"item\",\"&default\",\"&attrs\",\"@value\"],\"statements\":[[8,\"animated-container\",[[24,0,\"cl-overflow-hidden\"],[17,3]],[[\"@motion\"],[[32,0,[\"containerMotion\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,0],[[32,4]],[[\"use\"],[[32,0,[\"transition\"]]]],[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[14,0,\"cl-flex\"],[12],[2,\"\\n      \"],[18,2,[[32,1]]],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"animated-each\"]}",
    "meta": {
      "moduleName": "@coachlogix/cl-ui/components/cl-animated-each/template.hbs"
    }
  });
  _exports.default = _default;
});