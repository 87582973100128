define("@coachlogix/cl-ui/components/cl-animated-value/component", ["exports", "@ember-decorators/component", "@coachlogix/cl-ui/motions/cl-resize", "@coachlogix/cl-ui/transitions/cl-fade", "@coachlogix/cl-ui/transitions/cl-move-over", "@coachlogix/cl-ui/components/cl-animated-value/template"], function (_exports, _component, _clResize, _clFade, _clMoveOver, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _class, _class2, _temp;
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  let ClAnimatedValue = (_dec = (0, _component.tagName)(''), _dec2 = (0, _component.layout)(_template.default), _dec3 = Ember._action, _dec(_class = _dec2(_class = (_class2 = (_temp = class ClAnimatedValue extends Ember.Component {
    constructor(...args) {
      super(...args);
      _defineProperty(this, "axis", 'x');
      _defineProperty(this, "containerMotion", _clResize.ClResize);
      _defineProperty(this, "compare", Ember.compare);
      _defineProperty(this, "duration", 500);
    }
    rules({
      oldItems,
      newItems
    }) {
      if (this.fade) {
        return _clFade.default;
      } else {
        if (this.compare(oldItems[0], newItems[0]) < 0) {
          return this.axis === 'x' ? _clMoveOver.toLeft : _clMoveOver.toUp;
        } else {
          return this.axis === 'x' ? _clMoveOver.toRight : _clMoveOver.toDown;
        }
      }
    }
  }, _temp), (_applyDecoratedDescriptor(_class2.prototype, "rules", [_dec3], Object.getOwnPropertyDescriptor(_class2.prototype, "rules"), _class2.prototype)), _class2)) || _class) || _class);
  var _default = ClAnimatedValue;
  _exports.default = _default;
});