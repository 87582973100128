define("@coachlogix/cl-ui/components/cl-dialog/header/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "3+h5i3mu",
    "block": "{\"symbols\":[\"&attrs\",\"&default\"],\"statements\":[[8,\"cl-toolbar\",[[17,1]],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[18,2,null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "@coachlogix/cl-ui/components/cl-dialog/header/template.hbs"
    }
  });
  _exports.default = _default;
});