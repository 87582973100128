define("@coachlogix/cl-ui/components/cl-top-bar/row/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "vemoxGsv",
    "block": "{\"symbols\":[\"&attrs\",\"&default\"],\"statements\":[[11,\"div\"],[24,0,\"cl-flex\"],[17,1],[12],[2,\"\\n  \"],[18,2,[[30,[36,1],null,[[\"cell\"],[[30,[36,0],[\"cl-top-bar/row/cell\"],null]]]]]],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"component\",\"hash\"]}",
    "meta": {
      "moduleName": "@coachlogix/cl-ui/components/cl-top-bar/row/template.hbs"
    }
  });
  _exports.default = _default;
});