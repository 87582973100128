define("@coachlogix/cl-ui/components/cl-status-indicator/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "3GABRXBH",
    "block": "{\"symbols\":[\"&default\",\"@text\"],\"statements\":[[10,\"div\"],[15,0,[31,[\"cl-status-indicator cl-inline-block cl-rounded-full cl-status-indicator--\",[32,0,[\"variant\"]]]]],[15,5,[32,0,[\"customStyle\"]]],[12],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[6,[37,0],[[32,2]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[10,\"p\"],[14,0,\"cl-title-6 cl-mx-1\"],[12],[1,[32,2]],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[18,1,null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"if\"]}",
    "meta": {
      "moduleName": "@coachlogix/cl-ui/components/cl-status-indicator/template.hbs"
    }
  });
  _exports.default = _default;
});