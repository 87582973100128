define("@coachlogix/cl-ui/components/cl-tabs/tab/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "h9Iv7Eey",
    "block": "{\"symbols\":[\"@disabled\",\"@stretch\",\"&attrs\",\"&default\",\"@href\"],\"statements\":[[6,[37,0],[[32,5]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[11,\"a\"],[16,6,[30,[36,0],[[32,1],null,[32,5]],null]],[16,0,[31,[[32,0,[\"styleNamespace\"]],\" cl-title-7 cl-font-bold cl-uppercase cl-text-sm-uppercase cl-text-center cl-p-2 cl-outline-none \",[30,[36,0],[[32,2],\"cl-flex-1\"],null],\" \",[30,[36,0],[[32,0,[\"isActive\"]],\"active\"],null],\" \",[30,[36,0],[[32,1],\"disabled cl-pointer-events-none\",\"cl-cursor-pointer\"],null]]]],[24,\"role\",\"tab\"],[16,\"aria-selected\",[30,[36,0],[[32,0,[\"isActive\"]],\"true\",\"false\"],null]],[16,\"tabindex\",[30,[36,0],[[32,1],\"-1\"],null]],[17,3],[4,[38,1],[\"click\",[32,0,[\"handleClick\"]]],null],[12],[2,\"\\n    \"],[18,4,null],[2,\"\\n    \"],[8,\"cl-ripple\",[[24,0,\"primary\"]],[[],[]],null],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[11,\"button\"],[16,0,[31,[[32,0,[\"styleNamespace\"]],\" cl-title-7 cl-font-bold cl-uppercase cl-text-sm-uppercase cl-text-center cl-p-2 cl-outline-none \",[30,[36,0],[[32,2],\"cl-flex-1\"],null],\" \",[30,[36,0],[[32,0,[\"isActive\"]],\"active\"],null],\" \",[30,[36,0],[[32,1],\"disabled cl-pointer-events-none\",\"cl-cursor-pointer\"],null]]]],[24,\"role\",\"tab\"],[16,\"aria-selected\",[30,[36,0],[[32,0,[\"isActive\"]],\"true\",\"false\"],null]],[16,\"disabled\",[32,1]],[17,3],[24,4,\"button\"],[4,[38,1],[\"click\",[32,0,[\"handleClick\"]]],null],[12],[2,\"\\n    \"],[18,4,null],[2,\"\\n    \"],[8,\"cl-ripple\",[[24,0,\"primary\"]],[[],[]],null],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"if\",\"on\"]}",
    "meta": {
      "moduleName": "@coachlogix/cl-ui/components/cl-tabs/tab/template.hbs"
    }
  });
  _exports.default = _default;
});