define("ember-yeti-table/components/yeti-table/tbody/row/component", ["exports", "@ember-decorators/component", "ember-yeti-table/components/yeti-table/tbody/row/template"], function (_exports, _component, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _class, _class2;
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  /**
    Renders a `<tr>` element and yields the cell component.
    ```hbs
    <body.row as |row|>
      <row.cell>
        {{person.firstName}} #{{index}}
      </row.cell>
      <row.cell>
        {{person.lastName}}
      </row.cell>
      <row.cell>
        {{person.points}}
      </row.cell>
    </body.row>
    ```
    Remember you can cutomize each `<tr>` class or `@onClick` handler based on the row data
    because you have access to it from the body component.
  
    ```hbs
    <table.body as |body person|>
      <body.row class={{if person.isInvalid "error"}} as |row|>
        <row.cell>
          {{person.firstName}}
        </row.cell>
        <row.cell>
          {{person.lastName}}
        </row.cell>
        <row.cell>
          {{person.points}}
        </row.cell>
      </body.row>
    </table.body>
    ```
  
    @yield {object} row
    @yield {Component} row.cell - the cell component
  */
  let TBodyRow = (_dec = (0, _component.tagName)(''), _dec2 = (0, _component.layout)(_template.default), _dec3 = Ember._action, _dec(_class = _dec2(_class = (_class2 = class TBodyRow extends Ember.Component {
    constructor(...args) {
      super(...args);
      _defineProperty(this, "theme", void 0);
      _defineProperty(this, "columns", void 0);
      _defineProperty(this, "onClick", void 0);
      _defineProperty(this, "cells", Ember.A());
    }
    /**
     * Adds a click action to the row.
     */
    registerCell(cell) {
      let columns = this.get('columns');
      let prop = cell.get('prop');
      if (prop) {
        let column = columns.findBy('prop', prop);
        cell.set('column', column);
      } else {
        let index = this.get('cells.length');
        let column = columns[index];
        cell.set('column', column);
      }
      this.get('cells').addObject(cell);
    }
    unregisterCell(cell) {
      this.get('cells').removeObject(cell);
    }
    handleClick() {
      if (this.get('onClick')) {
        this.get('onClick')(...arguments);
      }
    }
  }, (_applyDecoratedDescriptor(_class2.prototype, "handleClick", [_dec3], Object.getOwnPropertyDescriptor(_class2.prototype, "handleClick"), _class2.prototype)), _class2)) || _class) || _class);
  var _default = TBodyRow;
  _exports.default = _default;
});