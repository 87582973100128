define("ember-animated/templates/components/animated-beacon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "xMbZBtRm",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[18,1,null]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "ember-animated/templates/components/animated-beacon.hbs"
    }
  });
  _exports.default = _default;
});