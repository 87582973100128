define("@coachlogix/cl-ui/components/cl-reveal/index", ["exports", "@glimmer/component", "resize-observer-polyfill"], function (_exports, _component, _resizeObserverPolyfill) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _class, _descriptor, _descriptor2, _descriptor3;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="cl-relative cl-overflow-hidden cl-transition-all" {{did-insert this.setupParentEl}} ...attributes>
    <div {{did-insert this.setupContentEl}}>
      {{yield this.expanded}}
    </div>
    {{#if (or (gt this.contentHeight this.parentHeight) this.expanded)}}
      {{!-- template-lint-disable no-inline-styles --}}
      <div
        class="cl-absolute cl-bottom-0 cl-inset-x-0 cl-flex cl-justify-center cl-bg-gradient-to-t cl-from-white cl-pt-2" {{did-insert this.setupHandleEl}}
        style="padding-bottom: inherit;padding-left: inherit;padding-right: inherit;">
        <ClButton @iconButton={{true}} @onClick={{this.handleToggle}}>
          <ClIcon @icon="keyboard_arrow_down" class="cl-duration-500 cl-transition-transform cl-ease-out-exp cl-transform {{if this.expanded "cl--rotate-180"}}"/>
        </ClButton>
      </div>
    {{/if}}
  </div>
  */
  {"id":"NA7uwM6c","block":"{\"symbols\":[\"&attrs\",\"&default\"],\"statements\":[[11,\"div\"],[24,0,\"cl-relative cl-overflow-hidden cl-transition-all\"],[17,1],[4,[38,0],[[32,0,[\"setupParentEl\"]]],null],[12],[2,\"\\n  \"],[11,\"div\"],[4,[38,0],[[32,0,[\"setupContentEl\"]]],null],[12],[2,\"\\n    \"],[18,2,[[32,0,[\"expanded\"]]]],[2,\"\\n  \"],[13],[2,\"\\n\"],[6,[37,1],[[30,[36,3],[[30,[36,2],[[32,0,[\"contentHeight\"]],[32,0,[\"parentHeight\"]]],null],[32,0,[\"expanded\"]]],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[11,\"div\"],[24,0,\"cl-absolute cl-bottom-0 cl-inset-x-0 cl-flex cl-justify-center cl-bg-gradient-to-t cl-from-white cl-pt-2\"],[24,5,\"padding-bottom: inherit;padding-left: inherit;padding-right: inherit;\"],[4,[38,0],[[32,0,[\"setupHandleEl\"]]],null],[12],[2,\"\\n      \"],[8,\"cl-button\",[],[[\"@iconButton\",\"@onClick\"],[true,[32,0,[\"handleToggle\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[8,\"cl-icon\",[[16,0,[31,[\"cl-duration-500 cl-transition-transform cl-ease-out-exp cl-transform \",[30,[36,1],[[32,0,[\"expanded\"]],\"cl--rotate-180\"],null]]]]],[[\"@icon\"],[\"keyboard_arrow_down\"]],null],[2,\"\\n      \"]],\"parameters\":[]}]]],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[13]],\"hasEval\":false,\"upvars\":[\"did-insert\",\"if\",\"gt\",\"or\"]}","meta":{"moduleName":"@coachlogix/cl-ui/components/cl-reveal/index.hbs"}});
  let ClReveal = (_dec = Ember._tracked, _dec2 = Ember._tracked, _dec3 = Ember._tracked, _dec4 = Ember._action, _dec5 = Ember._action, _dec6 = Ember._action, _dec7 = Ember._action, (_class = class ClReveal extends _component.default {
    constructor(...args) {
      super(...args);
      _defineProperty(this, "parentEl", void 0);
      _defineProperty(this, "contentEl", void 0);
      _defineProperty(this, "handleEl", void 0);
      _initializerDefineProperty(this, "contentHeight", _descriptor, this);
      _initializerDefineProperty(this, "parentHeight", _descriptor2, this);
      _initializerDefineProperty(this, "expanded", _descriptor3, this);
    }
    setupParentEl(element) {
      this.parentEl = element;
      this.parentHeight = element.offsetHeight;
    }
    setupContentEl(element) {
      let ro = new _resizeObserverPolyfill.default(entries => {
        for (let entry of entries) {
          let {
            height
          } = entry.contentRect;
          this.contentHeight = height;
        }
      });
      ro.observe(element);
      this.contentEl = element;
    }
    setupHandleEl(element) {
      this.handleEl = element;
    }
    handleToggle() {
      if (this.expanded) {
        this.parentEl.style.removeProperty('max-height');
        this.parentEl.style.removeProperty('height');
      } else {
        let {
          paddingTop
        } = window.getComputedStyle(this.parentEl);
        let totalHeight = this.contentHeight + parseFloat(paddingTop) + this.handleEl.offsetHeight;
        this.parentEl.style.setProperty('max-height', `${totalHeight}px`, 'important');
        this.parentEl.style.setProperty('height', `${totalHeight}px`, 'important');
      }
      this.expanded = !this.expanded;
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "contentHeight", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 0;
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "parentHeight", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 0;
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "expanded", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "setupParentEl", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "setupParentEl"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "setupContentEl", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "setupContentEl"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "setupHandleEl", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "setupHandleEl"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "handleToggle", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "handleToggle"), _class.prototype)), _class));
  _exports.default = ClReveal;
  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, ClReveal);
});