define("@coachlogix/cl-ui/components/cl-dropdown/content/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "gaRyEc64",
    "block": "{\"symbols\":[\"EbdContent\",\"@onMouseEnter\",\"@onMouseLeave\",\"&attrs\",\"&default\",\"@defaultClass\",\"@ebdContent\"],\"statements\":[[6,[37,3],[[30,[36,1],[[32,7]],[[\"defaultClass\",\"animationEnabled\"],[[32,6],false]]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[8,[32,1],[[4,[38,0],[\"mouseenter\",[32,2]],null],[4,[38,0],[\"mouseleave\",[32,3]],null]],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"cl-card\",[[24,0,\"cl-my-1\"],[17,4]],[[\"@raised\"],[true]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[18,5,[[30,[36,2],null,[[\"item\"],[[30,[36,1],[\"cl-dropdown/content/item\"],null]]]]]],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"on\",\"component\",\"hash\",\"let\"]}",
    "meta": {
      "moduleName": "@coachlogix/cl-ui/components/cl-dropdown/content/template.hbs"
    }
  });
  _exports.default = _default;
});