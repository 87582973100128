define("@coachlogix/cl-ui/components/cl-calendar/decade/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "JivNewPN",
    "block": "{\"symbols\":[\"year\",\"@onYearSelected\"],\"statements\":[[10,\"div\"],[14,0,\"cl-flex cl-flex-wrap cl--mx-0.5 cl-pt-1\"],[12],[2,\"\\n\"],[6,[37,2],[[30,[36,1],[[30,[36,1],[[32,0,[\"yearsInDecade\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[14,0,\"cl-w-1/3 cl-px-0.5 cl-my-0.5\"],[12],[2,\"\\n      \"],[8,\"cl-button\",[[24,0,\"cl-min-w-full\"]],[[\"@onClick\",\"@bordered\"],[[30,[36,0],[[32,2],[32,1]],null],true]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[1,[32,1]],[2,\"\\n      \"]],\"parameters\":[]}]]],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[13]],\"hasEval\":false,\"upvars\":[\"fn\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "@coachlogix/cl-ui/components/cl-calendar/decade/template.hbs"
    }
  });
  _exports.default = _default;
});