define("@coachlogix/cl-ui/components/cl-user-avatar-group/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "hqBeiSn+",
    "block": "{\"symbols\":[\"&attrs\",\"&default\"],\"statements\":[[11,\"div\"],[16,0,[31,[\"cl-flex cl-flex-row \",[32,0,[\"styleNamespace\"]]]]],[17,1],[12],[2,\"\\n  \"],[18,2,[[30,[36,2],null,[[\"avatar\",\"button\"],[[30,[36,1],[\"cl-user-avatar\"],[[\"size\",\"class\"],[32,[30,[36,0],[[32,0,[\"styleNamespace\"]],\"-avatar\"],null]]]],[30,[36,1],[\"cl-button\"],[[\"raised\",\"iconButton\",\"primary\",\"class\"],[true,true,true,[30,[36,0],[[32,0,[\"styleNamespace\"]],\"-button\"],null]]]]]]]]],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"concat\",\"component\",\"hash\"]}",
    "meta": {
      "moduleName": "@coachlogix/cl-ui/components/cl-user-avatar-group/template.hbs"
    }
  });
  _exports.default = _default;
});