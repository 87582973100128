define("@coachlogix/cl-ui/components/cl-tabs/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "G7XUHdd5",
    "block": "{\"symbols\":[\"&attrs\",\"@selected\",\"&default\"],\"statements\":[[11,\"div\"],[16,0,[31,[[32,0,[\"styleNamespace\"]],\" cl-flex\"]]],[24,\"role\",\"tablist\"],[17,1],[12],[2,\"\\n  \"],[18,3,[[30,[36,2],null,[[\"tab\"],[[30,[36,1],[\"cl-tabs/tab\"],[[\"registerTab\",\"unregisterTab\",\"selectedTab\",\"onChange\",\"stretch\"],[[30,[36,0],[[32,0,[\"registerTab\"]]],null],[30,[36,0],[[32,0,[\"unregisterTab\"]]],null],[32,2],[30,[36,0],[[32,0,[\"tabSelected\"]]],null],[32,0,[\"stretch\"]]]]]]]]]],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"fn\",\"component\",\"hash\"]}",
    "meta": {
      "moduleName": "@coachlogix/cl-ui/components/cl-tabs/template.hbs"
    }
  });
  _exports.default = _default;
});