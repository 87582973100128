define("@coachlogix/cl-ui/helpers/underscore", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.underscore = underscore;
  function underscore([text]) {
    return Ember.String.underscore(text);
  }
  var _default = Ember.Helper.helper(underscore);
  _exports.default = _default;
});