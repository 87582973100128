define("ember-animated/templates/components/animated-if", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "i1Nksi2v",
    "block": "{\"symbols\":[\"currentPredicate\",\"&else\",\"&default\"],\"statements\":[[6,[37,1],[[32,0,[\"predicate\"]]],[[\"key\",\"rules\",\"use\",\"duration\",\"group\",\"initialInsertion\",\"finalRemoval\"],[[32,0,[\"key\"]],[32,0,[\"rules\"]],[32,0,[\"use\"]],[32,0,[\"duration\"]],[32,0,[\"realGroup\"]],[32,0,[\"initialInsertion\"]],[32,0,[\"finalRemoval\"]]]],[[\"default\"],[{\"statements\":[[6,[37,0],[[32,1]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[18,3,null],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[18,2,null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"if\",\"animated-value\"]}",
    "meta": {
      "moduleName": "ember-animated/templates/components/animated-if.hbs"
    }
  });
  _exports.default = _default;
});