define("@coachlogix/cl-ui/components/cl-truncate/component", ["exports", "@ember-decorators/component", "shave", "@coachlogix/cl-ui/components/cl-truncate/template"], function (_exports, _component, _shave, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _class2, _temp;
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function computeStyle(element, prop) {
    // TODO: workaround https://bugzilla.mozilla.org/show_bug.cgi?id=548397
    return window.getComputedStyle(element, null).getPropertyValue(prop);
  }
  function getLineHeight(element) {
    const lh = computeStyle(element, 'line-height');
    return lh === 'normal' ?
    // Handle line-height: normal case
    parseInt(computeStyle(element, 'font-size')) * 1.5 :
    // Regular px case.
    parseInt(lh);
  }
  function getTextHeight(element, lines) {
    const lineHeight = getLineHeight(element);
    return lineHeight * lines;
  }
  let ClTruncate = (_dec = (0, _component.tagName)(''), _dec2 = (0, _component.layout)(_template.default), _dec3 = Ember._action, _dec4 = Ember._action, _dec5 = Ember._action, _dec(_class = _dec2(_class = (_class2 = (_temp = class ClTruncate extends Ember.Component {
    constructor(...args) {
      super(...args);
      _defineProperty(this, "lines", 4);
      _defineProperty(this, "truncated", false);
      _defineProperty(this, "expanded", false);
    }
    setupClamp(textEl, text) {
      textEl.textContent = text;
      this.set('expanded', false);
      (0, _shave.default)(textEl, getTextHeight(textEl, this.lines) + 9);
      if (textEl.querySelector('.js-shave-char')) {
        this.set('truncated', true);
      }
      this.textEl = textEl;
    }
    reveal() {
      let ellipsis = this.textEl.querySelector('.js-shave-char');
      let hiddenText = this.textEl.querySelector('.js-shave');
      ellipsis.style.display = 'none';
      hiddenText.style.display = 'inline';
      this.set('expanded', true);
    }
    collapse() {
      let ellipsis = this.textEl.querySelector('.js-shave-char');
      let hiddenText = this.textEl.querySelector('.js-shave');
      ellipsis.style.display = 'inline';
      hiddenText.style.display = 'none';
      this.set('expanded', false);
    }
  }, _temp), (_applyDecoratedDescriptor(_class2.prototype, "setupClamp", [_dec3], Object.getOwnPropertyDescriptor(_class2.prototype, "setupClamp"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "reveal", [_dec4], Object.getOwnPropertyDescriptor(_class2.prototype, "reveal"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "collapse", [_dec5], Object.getOwnPropertyDescriptor(_class2.prototype, "collapse"), _class2.prototype)), _class2)) || _class) || _class);
  var _default = ClTruncate;
  _exports.default = _default;
});