define("@coachlogix/cl-ui/helpers/markdown", ["exports", "markdown-it", "markdown-it-link-attributes"], function (_exports, _markdownIt, _markdownItLinkAttributes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.markdown = markdown;
  const md = new _markdownIt.default({
    linkify: true,
    typographer: true,
    breaks: true
  });
  md.use(_markdownItLinkAttributes.default, {
    attrs: {
      target: '_blank',
      rel: 'noopener',
      class: 'cl-link'
    }
  });
  function markdown([markdownString = ''], {
    renderInline = false
  }) {
    let html = renderInline ? md.renderInline(markdownString) : md.render(markdownString);
    return Ember.String.htmlSafe(html);
  }
  var _default = Ember.Helper.helper(markdown);
  _exports.default = _default;
});