define("@coachlogix/cl-ui/components/cl-callout/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "kKU2GgAo",
    "block": "{\"symbols\":[\"@primary\",\"@secondary\",\"@success\",\"@info\",\"@warn\",\"@danger\",\"&attrs\",\"&default\",\"@dismissable\"],\"statements\":[[8,\"cl-animated-if\",[],[[\"@value\"],[[30,[36,2],[[32,0,[\"dismiss\"]]],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[11,\"div\"],[16,0,[31,[\"cl-flex cl-flex-col cl-p-4 cl-relative cl-pr-6\\n      \",[32,0,[\"styleNamespace\"]],\"\\n      \",[30,[36,3],[[32,1],\"primary\"],null],\"\\n      \",[30,[36,3],[[32,2],\"secondary\"],null],\"\\n      \",[30,[36,3],[[32,3],\"success\"],null],\"\\n      \",[30,[36,3],[[32,4],\"info\"],null],\"\\n      \",[30,[36,3],[[32,5],\"warn\"],null],\"\\n      \",[30,[36,3],[[32,6],\"danger\"],null]]]],[17,7],[12],[2,\"\\n    \"],[18,8,null],[2,\"\\n\"],[6,[37,3],[[32,9]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[8,\"cl-button\",[[24,0,\"close-btn cl-bottom-0 cl-right-0 cl-z-10 cl-flex cl-align-center cl-border-0 cl-m-0 cl-p-1 cl-outline-none cl-cursor-pointer\"]],[[\"@raised\",\"@iconButton\",\"@primary\",\"@secondary\",\"@success\",\"@info\",\"@warn\",\"@danger\",\"@onClick\"],[true,true,[32,1],[32,2],[32,3],[32,4],[32,5],[32,6],[30,[36,1],[[30,[36,0],[[32,0,[\"dismiss\"]]],null],true],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[8,\"cl-icon\",[],[[\"@icon\"],[\"close\"]],null],[2,\"\\n      \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"mut\",\"fn\",\"not\",\"if\"]}",
    "meta": {
      "moduleName": "@coachlogix/cl-ui/components/cl-callout/template.hbs"
    }
  });
  _exports.default = _default;
});