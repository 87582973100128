define("ember-can/computed", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.ability = ability;
  function ability(abilityName, resourceName) {
    resourceName = resourceName || abilityName;
    return Ember.computed(resourceName, function () {
      let canService = Ember.getOwner(this).lookup('service:can');
      return canService.abilityFor(abilityName, this.get(resourceName));
    }).readOnly();
  }
});