define("@coachlogix/cl-ui/components/cl-infinite-pagination/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "8x7OO0ai",
    "block": "{\"symbols\":[\"@noOptionsIcon\",\"@noOptionsText\",\"&default\",\"&attrs\"],\"statements\":[[11,\"div\"],[24,0,\"cl-overflow-auto cl-flex cl-flex-col\"],[17,4],[12],[2,\"\\n\"],[6,[37,0],[[32,0,[\"loadFirstPage\",\"isRunning\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[14,0,\"cl-flex-1 cl-flex cl-flex-col cl-items-center cl-justify-center cl-p-3\"],[12],[2,\"\\n      \"],[8,\"cl-progress-circular\",[],[[],[]],null],[2,\"\\n      \"],[10,\"p\"],[14,0,\"cl-mt-1\"],[12],[1,[30,[36,1],[\"cl-infinite-pagination.loading\"],null]],[13],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,0],[[32,0,[\"items\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"      \"],[18,3,[[32,0,[\"items\"]]]],[2,\"\\n\\n\"],[6,[37,0],[[32,0,[\"loadMore\",\"isRunning\"]]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[8,\"cl-progress-linear\",[],[[],[]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[6,[37,0],[[30,[36,4],[[30,[36,3],[[32,0,[\"loadMore\",\"isRunning\"]]],null],[30,[36,3],[[32,0,[\"cantLoadMore\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[8,\"cl-in-viewport\",[],[[\"@didEnter\"],[[30,[36,2],[[32,0,[\"loadMore\"]]],null]]],null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]},{\"statements\":[[2,\"      \"],[10,\"div\"],[14,0,\"cl-flex-1 cl-flex cl-flex-col cl-items-center cl-justify-center cl-p-3\"],[12],[2,\"\\n        \"],[8,\"cl-empty-state\",[],[[\"@icon\",\"@size\",\"@text\"],[[30,[36,0],[[32,1],[32,1],\"face\"],null],48,[30,[36,0],[[32,2],[32,2],[30,[36,1],[\"cl-infinite-pagination.empty\"],null]],null]]],null],[2,\"\\n      \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[13]],\"hasEval\":false,\"upvars\":[\"if\",\"t\",\"perform\",\"not\",\"and\"]}",
    "meta": {
      "moduleName": "@coachlogix/cl-ui/components/cl-infinite-pagination/template.hbs"
    }
  });
  _exports.default = _default;
});