define("@coachlogix/cl-ui/components/cl-date-range-picker/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "PLbfpAgO",
    "block": "{\"symbols\":[\"dd\",\"@placeholder\",\"@label\",\"@selected\",\"&default\",\"@disabled\",\"@horizontalPosition\",\"@verticalPosition\",\"&attrs\",\"@minDate\",\"@maxDate\"],\"statements\":[[8,\"cl-dropdown\",[],[[\"@disabled\",\"@horizontalPosition\",\"@verticalPosition\"],[[32,6],[32,7],[32,8]]],[[\"default\"],[{\"statements\":[[2,\"\\n\\n  \"],[8,[32,1,[\"trigger\"]],[[17,9]],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,1],[[27,[32,5]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"      \"],[18,5,null],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"      \"],[8,\"cl-input\",[[16,\"placeholder\",[32,2]],[24,\"readonly\",\"\"]],[[\"@label\",\"@value\"],[[32,3],[30,[36,0],[[32,4]],null]]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"]],\"parameters\":[]}]]],[2,\"\\n\\n  \"],[8,[32,1,[\"content\"]],[[24,0,\"cl-p-2\"]],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"cl-calendar\",[],[[\"@mini\",\"@center\",\"@onCenterChange\",\"@minDate\",\"@maxDate\",\"@selected\",\"@onSelect\"],[true,[32,0,[\"center\"]],[30,[36,3],[[30,[36,2],[[32,0,[\"center\"]]],null]],null],[32,10],[32,11],[32,4],[30,[36,3],[[32,0,[\"handleSelect\"]],[32,1]],null]]],null],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"format-date\",\"if\",\"mut\",\"fn\"]}",
    "meta": {
      "moduleName": "@coachlogix/cl-ui/components/cl-date-range-picker/template.hbs"
    }
  });
  _exports.default = _default;
});