define("@coachlogix/cl-ui/easings/ease-exp-out", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = easeExpOut;
  function easeExpOut(t) {
    return 1 - Math.pow(2, -10 * t);
  }
});