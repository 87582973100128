define("ember-animated/color", ["exports", "ember-animated", "ember-animated/easings/linear", "ember-animated/element-remove"], function (_exports, _emberAnimated, _linear, _elementRemove) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.ColorTween = _exports.Color = void 0;
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); } // We use Element.remove in our color parser below.
  class Color {
    static fromComputedStyle(colorString) {
      let channels = parseComputedColor(colorString);
      return new Color(channels, channels.m[0]);
    }
    static fromUserProvidedColor(colorString) {
      return new Color(parseUserProvidedColor(colorString), colorString);
    }
    toString() {
      return `rgba(${this.r}, ${this.g}, ${this.b}, ${this.a})`;
    }
    constructor({
      r,
      g,
      b,
      a
    }, sourceString) {
      this.sourceString = sourceString;
      _defineProperty(this, "r", void 0);
      _defineProperty(this, "g", void 0);
      _defineProperty(this, "b", void 0);
      _defineProperty(this, "a", void 0);
      this.r = r;
      this.g = g;
      this.b = b;
      this.a = a;
    }
  }
  _exports.Color = Color;
  class ColorTween {
    constructor(initialColor, finalColor, duration, easing = _linear.default) {
      _defineProperty(this, "rTween", void 0);
      _defineProperty(this, "gTween", void 0);
      _defineProperty(this, "bTween", void 0);
      _defineProperty(this, "aTween", void 0);
      this.rTween = new _emberAnimated.Tween(initialColor.r * initialColor.a, finalColor.r * finalColor.a, duration, easing);
      this.gTween = new _emberAnimated.Tween(initialColor.g * initialColor.a, finalColor.g * finalColor.a, duration, easing);
      this.bTween = new _emberAnimated.Tween(initialColor.b * initialColor.a, finalColor.b * finalColor.a, duration, easing);
      this.aTween = new _emberAnimated.Tween(initialColor.a, finalColor.a, duration, easing);
    }
    get currentValue() {
      let nonZeroAlpha = this.aTween.currentValue || 1;
      return new Color({
        r: Math.floor(this.rTween.currentValue / nonZeroAlpha),
        g: Math.floor(this.gTween.currentValue / nonZeroAlpha),
        b: Math.floor(this.bTween.currentValue / nonZeroAlpha),
        a: this.aTween.currentValue
      }, '');
    }
    get done() {
      return [this.rTween, this.gTween, this.bTween, this.aTween].every(tween => tween.done);
    }
  }
  _exports.ColorTween = ColorTween;
  function parseComputedColor(c) {
    let m = /^rgb\((\d+), (\d+), (\d+)\)/.exec(c);
    if (m) {
      return {
        r: parseInt(m[1]),
        g: parseInt(m[2]),
        b: parseInt(m[3]),
        a: 1,
        m
      };
    }
    m = /^rgba\((\d+), (\d+), (\d+), (\d+(?:\.\d+)?)\)/.exec(c);
    if (m) {
      return {
        r: parseInt(m[1]),
        g: parseInt(m[2]),
        b: parseInt(m[3]),
        a: parseFloat(m[4]),
        m
      };
    }
    throw new Error(`unable to parse color ${c}`);
  }
  function parseUserProvidedColor(c) {
    let testElement = document.createElement('div');
    testElement.style.display = 'none';
    testElement.style.color = c;
    document.body.appendChild(testElement);
    let result = parseComputedColor(getComputedStyle(testElement).color);
    testElement.remove();
    return result;
  }
});