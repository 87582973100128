define("@coachlogix/cl-ui/components/cl-input-inline/component", ["exports", "@ember-decorators/component", "ember-animated/transitions/fade", "ember-concurrency-decorators", "@coachlogix/cl-ui/components/cl-input-inline/template"], function (_exports, _component, _fade, _emberConcurrencyDecorators, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _class, _class2, _temp;
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  let ClInputInline = (_dec = (0, _component.tagName)(''), _dec2 = (0, _component.layout)(_template.default), _dec3 = Ember._action, _dec4 = Ember._action, _dec5 = Ember._action, _dec6 = Ember._action, _dec7 = Ember._action, _dec8 = Ember._action, _dec(_class = _dec2(_class = (_class2 = (_temp = class ClInputInline extends Ember.Component {
    constructor(...args) {
      super(...args);
      _defineProperty(this, "fade", _fade.default);
      _defineProperty(this, "isEditing", false);
      _defineProperty(this, "buffer", '');
      _defineProperty(this, "showEditButtons", true);
    }
    focusOnInsert(element) {
      element.focus();
      this.inputEl = element;
    }
    startEditing() {
      if (!this.disabled && this.onChange) {
        this.set('buffer', this.value);
        this.set('isEditing', true);
      }
    }
    stopEditing() {
      this.save.perform();
    }
    handleMousedown(e) {
      e.preventDefault();
      this.inputEl.focus();
    }
    handleKeydown(event) {
      if (event.key === 'Escape') {
        this.discard();
      }
    }
    discard() {
      this.set('isEditing', false);
      this.set('buffer', this.value);
    }
    *save() {
      if (this.onChange) {
        yield this.onChange(this.buffer);
      }
      this.set('isEditing', false);
    }
  }, _temp), (_applyDecoratedDescriptor(_class2.prototype, "focusOnInsert", [_dec3], Object.getOwnPropertyDescriptor(_class2.prototype, "focusOnInsert"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "startEditing", [_dec4], Object.getOwnPropertyDescriptor(_class2.prototype, "startEditing"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "stopEditing", [_dec5], Object.getOwnPropertyDescriptor(_class2.prototype, "stopEditing"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "handleMousedown", [_dec6], Object.getOwnPropertyDescriptor(_class2.prototype, "handleMousedown"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "handleKeydown", [_dec7], Object.getOwnPropertyDescriptor(_class2.prototype, "handleKeydown"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "discard", [_dec8], Object.getOwnPropertyDescriptor(_class2.prototype, "discard"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "save", [_emberConcurrencyDecorators.dropTask], Object.getOwnPropertyDescriptor(_class2.prototype, "save"), _class2.prototype)), _class2)) || _class) || _class);
  var _default = ClInputInline;
  _exports.default = _default;
});