define("@coachlogix/cl-ui/components/cl-comment-submit/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "b3USv/+K",
    "block": "{\"symbols\":[\"&attrs\",\"@avatarUrl\",\"@email\",\"@firstName\",\"@lastName\",\"@placeholder\"],\"statements\":[[11,\"div\"],[24,0,\"cl-flex\"],[17,1],[12],[2,\"\\n  \"],[8,\"cl-user-avatar\",[],[[\"@imageUrl\",\"@email\",\"@firstName\",\"@lastName\",\"@size\"],[[32,2],[32,3],[32,4],[32,5],36]],null],[2,\"\\n\\n  \"],[8,\"cl-input\",[[16,\"disabled\",[32,0,[\"submit\",\"isRunning\"]]],[24,\"rows\",\"1\"],[16,\"placeholder\",[30,[36,3],[[32,6],[32,6],[30,[36,0],[\"cl-comment-submit.placeholder\"],null]],null]]],[[\"@solid\",\"@value\",\"@onChange\",\"@containerClass\",\"@textarea\"],[true,[32,0,[\"comment\"]],[30,[36,2],[[30,[36,1],[[32,0,[\"comment\"]]],null]],null],\"cl-flex-1 cl-mx-3\",true]],null],[2,\"\\n\\n  \"],[8,\"cl-button\",[[16,\"disabled\",[30,[36,4],[[32,0,[\"isEmpty\"]],[32,0,[\"submit\",\"isRunning\"]]],null]]],[[\"@primary\",\"@raised\",\"@onClick\"],[true,true,[30,[36,5],[[32,0,[\"submit\"]]],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,3],[[32,0,[\"submit\",\"isRunning\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"      \"],[1,[30,[36,0],[\"cl-comment-submit.submitting\"],null]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"      \"],[1,[30,[36,0],[\"cl-comment-submit.comment\"],null]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"]],\"parameters\":[]}]]],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"t\",\"mut\",\"fn\",\"if\",\"or\",\"perform\"]}",
    "meta": {
      "moduleName": "@coachlogix/cl-ui/components/cl-comment-submit/template.hbs"
    }
  });
  _exports.default = _default;
});