define("@coachlogix/cl-ui/components/cl-paged-card/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "t5UeDZlv",
    "block": "{\"symbols\":[\"currentPage\",\"&attrs\",\"&default\"],\"statements\":[[8,\"cl-card\",[[24,0,\"cl-flex cl-flex-col\"],[17,2]],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[10,\"div\"],[14,0,\"cl-flex-1\"],[12],[2,\"\\n    \"],[8,\"cl-animated-value\",[],[[\"@value\"],[[32,0,[\"page\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[18,3,[[30,[36,1],null,[[\"page\"],[[30,[36,0],[\"cl-paged-card/page\"],[[\"class\",\"registerPage\",\"unregisterPage\",\"currentPage\"],[\"cl-p-3\",[32,0,[\"registerPage\"]],[32,0,[\"unregisterPage\"]],[32,1]]]]]]]]],[2,\"\\n    \"]],\"parameters\":[1]}]]],[2,\"\\n  \"],[13],[2,\"\\n\"],[6,[37,3],[[30,[36,2],[[32,0,[\"pages\",\"length\"]],1],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[8,\"cl-dot-pager\",[[24,0,\"cl-p-3\"]],[[\"@page\",\"@totalPages\",\"@onChange\"],[[32,0,[\"page\"]],[32,0,[\"pages\",\"length\"]],[32,0,[\"onPageChange\"]]]],null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"component\",\"hash\",\"gt\",\"if\"]}",
    "meta": {
      "moduleName": "@coachlogix/cl-ui/components/cl-paged-card/template.hbs"
    }
  });
  _exports.default = _default;
});