define("@coachlogix/cl-ui/components/cl-chart-bar/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "NzbefHc7",
    "block": "{\"symbols\":[\"chart\",\"@ratio\",\"&attrs\",\"&default\"],\"statements\":[[8,\"cl-chart\",[[17,3]],[[\"@type\",\"@ratio\",\"@options\"],[\"bar\",[32,2],[32,0,[\"chartOptions\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[18,4,[[32,1]]],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "@coachlogix/cl-ui/components/cl-chart-bar/template.hbs"
    }
  });
  _exports.default = _default;
});