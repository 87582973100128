define("@coachlogix/cl-ui/components/cl-chart/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "mE4V3/KB",
    "block": "{\"symbols\":[\"&default\",\"@ratio\",\"&attrs\"],\"statements\":[[18,1,[[30,[36,1],null,[[\"value\"],[[30,[36,0],[\"cl-chart/value\"],[[\"addValue\",\"removeValue\",\"updateValue\"],[[32,0,[\"addValue\"]],[32,0,[\"removeValue\"]],[32,0,[\"updateValue\"]]]]]]]]]],[2,\"\\n\\n\"],[8,\"chartist-chart\",[[17,3]],[[\"@type\",\"@ratio\",\"@data\",\"@options\"],[[32,0,[\"type\"]],[32,2],[32,0,[\"data\"]],[32,0,[\"chartOptions\"]]]],null]],\"hasEval\":false,\"upvars\":[\"component\",\"hash\"]}",
    "meta": {
      "moduleName": "@coachlogix/cl-ui/components/cl-chart/template.hbs"
    }
  });
  _exports.default = _default;
});