define("@coachlogix/cl-ui/components/cl-progress-circular/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "pcV92tdq",
    "block": "{\"symbols\":[\"@warn\",\"@secondary\",\"@primary\",\"@value\",\"&attrs\"],\"statements\":[[11,\"div\"],[16,0,[31,[[32,0,[\"styleNamespace\"]],\" \",[32,0,[\"variant\"]],\" \",[30,[36,0],[[32,3],\"primary\"],null],\" \",[30,[36,0],[[32,2],\"secondary\"],null],\" \",[30,[36,0],[[32,1],\"warn\"],null]]]],[16,5,[32,0,[\"containerStyle\"]]],[24,\"role\",\"progressbar\"],[16,\"aria-valuenow\",[30,[36,0],[[30,[36,1],[[32,0,[\"variant\"]],\"determinate\"],null],[32,4]],null]],[17,5],[12],[2,\"\\n  \"],[10,\"svg\"],[14,\"viewBox\",\"22 22 44 44\"],[12],[2,\"\\n    \"],[10,\"circle\"],[14,\"cx\",\"44\"],[14,\"cy\",\"44\"],[14,\"r\",\"20.2\"],[14,\"fill\",\"none\"],[14,\"stroke-width\",\"3.6\"],[15,5,[32,0,[\"circleStyle\"]]],[12],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"if\",\"eq\"]}",
    "meta": {
      "moduleName": "@coachlogix/cl-ui/components/cl-progress-circular/template.hbs"
    }
  });
  _exports.default = _default;
});