define("ember-drag-sort/templates/components/drag-sort-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "YxQ43+V4",
    "block": "{\"symbols\":[\"item\",\"index\",\"&default\"],\"statements\":[[6,[37,14],[[30,[36,13],[[30,[36,13],[[35,10]],null]],null]],null,[[\"default\"],[{\"statements\":[[6,[37,12],null,[[\"additionalArgs\",\"item\",\"index\",\"items\",\"group\",\"handle\",\"class\",\"tagName\",\"isHorizontal\",\"isRtl\",\"draggingEnabled\",\"dragEndAction\",\"determineForeignPositionAction\",\"sourceOnly\"],[[35,11],[32,1],[32,2],[35,10],[35,9],[35,8],[35,7],[35,6],[35,5],[35,4],[35,3],[35,2],[35,1],[35,0]]],[[\"default\"],[{\"statements\":[[2,\"    \"],[18,3,[[32,1],[32,2]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[1,2]}]]]],\"hasEval\":false,\"upvars\":[\"sourceOnly\",\"determineForeignPositionAction\",\"dragEndAction\",\"draggingEnabled\",\"isRtl\",\"isHorizontal\",\"childTagName\",\"childClass\",\"handle\",\"group\",\"items\",\"additionalArgs\",\"drag-sort-item\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "ember-drag-sort/templates/components/drag-sort-list.hbs"
    }
  });
  _exports.default = _default;
});