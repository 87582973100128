define("@coachlogix/cl-ui/components/cl-chart-stacked/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "W61l8DJl",
    "block": "{\"symbols\":[\"v\",\"&attrs\",\"@chartClass\",\"&default\",\"@legend\"],\"statements\":[[11,\"div\"],[24,0,\"cl-flex cl-flex-col\"],[17,2],[12],[2,\"\\n  \"],[10,\"div\"],[15,0,[31,[\"cl-flex cl-h-1 cl-w-full cl-overflow-hidden \",[32,3]]]],[12],[2,\"\\n    \"],[18,4,[[30,[36,5],null,[[\"bar\"],[[30,[36,4],[\"cl-chart-stacked/bar\"],[[\"registerValue\",\"unregisterValue\"],[[32,0,[\"registerValue\"]],[32,0,[\"unregisterValue\"]]]]]]]]]],[2,\"\\n  \"],[13],[2,\"\\n\\n\"],[6,[37,6],[[32,5]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[14,0,\"cl-flex cl-space-x-3 cl-mt-3\"],[12],[2,\"\\n\"],[6,[37,3],[[30,[36,2],[[30,[36,2],[[32,0,[\"values\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[10,\"div\"],[14,0,\"cl-flex cl-items-center\"],[12],[2,\"\\n          \"],[10,\"div\"],[15,0,[31,[[30,[36,1],[[32,1,[\"color\"]],[30,[36,0],[\"cl-bg-\",[32,1,[\"variant\"]]],null]],null],\" cl-w-3 cl-h-3 cl-rounded cl-mr-1\"]]],[15,5,[32,1,[\"colorStyle\"]]],[12],[13],[2,\"\\n          \"],[1,[32,1,[\"labelEl\"]]],[2,\"\\n        \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[13]],\"hasEval\":false,\"upvars\":[\"concat\",\"unless\",\"-track-array\",\"each\",\"component\",\"hash\",\"if\"]}",
    "meta": {
      "moduleName": "@coachlogix/cl-ui/components/cl-chart-stacked/template.hbs"
    }
  });
  _exports.default = _default;
});