define("@coachlogix/cl-ui/components/cl-infinite-pagination/component", ["exports", "@ember-decorators/component", "ember-concurrency-decorators", "@coachlogix/cl-ui/components/cl-infinite-pagination/template"], function (_exports, _component, _emberConcurrencyDecorators, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _class, _class2, _temp;
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  let ClInfinitePagination = (_dec = (0, _component.tagName)(''), _dec2 = (0, _component.layout)(_template.default), _dec3 = (0, _emberConcurrencyDecorators.enqueueTask)({
    on: 'init'
  }), _dec4 = Ember._action, _dec(_class = _dec2(_class = (_class2 = (_temp = class ClInfinitePagination extends Ember.Component {
    constructor(...args) {
      super(...args);
      _defineProperty(this, "pageNumber", 0);
      _defineProperty(this, "pageSize", 10);
      _defineProperty(this, "cantLoadMore", false);
      _defineProperty(this, "items", Ember.A());
    }
    get paginationData() {
      return {
        pageNumber: this.pageNumber,
        pageSize: this.pageSize,
        cantLoadMore: this.cantLoadMore
      };
    }
    init() {
      super.init(...arguments);
      if (this.registerApi) {
        Ember.run.scheduleOnce('actions', null, this.registerApi, {
          reset: this.reset
        });
      }
    }
    *loadItems() {
      if (typeof this.loadData === 'function') {
        this.pageNumber++;
        let params = {
          pagination: this.paginationData
        };
        let items = yield this.loadData(params);
        items = items.toArray ? items.toArray() : items;
        if (!items || !items.length || items.length < this.pageSize) {
          this.set('cantLoadMore', true);
        }
        this.items.addObjects(items);
      }
    }
    *loadFirstPage() {
      return yield this.loadItems.perform();
    }
    *loadMore() {
      return yield this.loadItems.perform();
    }
    reset() {
      // reset pagination properties
      this.pageNumber = 0;
      this.items.clear();
      this.set('cantLoadMore', false);
      this.loadFirstPage.cancelAll();
      this.loadMore.cancelAll();
      this.loadFirstPage.perform();
    }
  }, _temp), (_applyDecoratedDescriptor(_class2.prototype, "loadItems", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class2.prototype, "loadItems"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "loadFirstPage", [_dec3], Object.getOwnPropertyDescriptor(_class2.prototype, "loadFirstPage"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "loadMore", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class2.prototype, "loadMore"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "reset", [_dec4], Object.getOwnPropertyDescriptor(_class2.prototype, "reset"), _class2.prototype)), _class2)) || _class) || _class);
  var _default = ClInfinitePagination;
  _exports.default = _default;
});