define("@coachlogix/cl-ui/components/cl-color-picker/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "yktYQfeo",
    "block": "{\"symbols\":[\"&attrs\",\"&default\",\"@value\",\"@disabled\"],\"statements\":[[11,\"div\"],[17,1],[4,[38,0],[[32,0,[\"setupPickr\"]]],null],[4,[38,1],[[32,0,[\"destroyPickr\"]]],null],[12],[2,\"\\n  \"],[18,2,null],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[1,[30,[36,2],[[32,0,[\"updateColor\"]],[32,3]],null]],[2,\"\\n\"],[1,[30,[36,2],[[32,0,[\"updateDisabled\"]],[32,4]],null]]],\"hasEval\":false,\"upvars\":[\"did-insert\",\"will-destroy\",\"did-update\"]}",
    "meta": {
      "moduleName": "@coachlogix/cl-ui/components/cl-color-picker/template.hbs"
    }
  });
  _exports.default = _default;
});