define("liquid-fire/templates/components/liquid-versions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "pO7+ASef",
    "block": "{\"symbols\":[\"version\",\"&default\"],\"statements\":[[6,[37,8],[[30,[36,7],[[30,[36,7],[[35,6]],null]],null]],null,[[\"default\"],[{\"statements\":[[6,[37,5],[[30,[36,4],[[35,3],[32,1,[\"value\"]]],null]],null,[[\"default\"],[{\"statements\":[[6,[37,2],null,[[\"version\",\"liquidChildDidRender\",\"class\"],[[32,1],[30,[36,1],[[32,0],\"childDidRender\"],null],[35,0]]],[[\"default\"],[{\"statements\":[[18,2,[[32,1,[\"value\"]]]]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"class\",\"action\",\"liquid-child\",\"renderWhenFalse\",\"lf-or\",\"if\",\"versions\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "liquid-fire/templates/components/liquid-versions.hbs"
    }
  });
  _exports.default = _default;
});