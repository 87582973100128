define("ember-filestack/helpers/filestack-url", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Helper.extend({
    filestack: Ember.inject.service(),
    compute([handleOrUrl], transformations) {
      if (typeof FastBoot !== 'undefined') {
        return;
      }
      if (Ember.isBlank(handleOrUrl)) {
        return;
      }
      if (!this.filestack.client) {
        this.filestack.initClient().then(() => {
          // workaround bug https://github.com/emberjs/ember.js/issues/14774
          Ember.run.join(() => this.recompute());
        });
        return;
      }
      return this.filestack.getUrl(handleOrUrl, transformations);
    }
  });
  _exports.default = _default;
});