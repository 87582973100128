define("@coachlogix/cl-ui/components/cl-button/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "1cHWllug",
    "block": "{\"symbols\":[\"Tag\",\"@bordered\",\"@iconButton\",\"@raised\",\"@active\",\"@mini\",\"@href\",\"@disabled\",\"&attrs\",\"&default\"],\"statements\":[[6,[37,7],[[30,[36,6],[[30,[36,5],[[30,[36,3],[[32,7],\"a\",\"button\"],null]],null]],[[\"tagName\"],[[30,[36,3],[[32,7],\"a\",\"button\"],null]]]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[8,[32,1],[[16,0,[31,[[32,0,[\"styleNamespace\"]],\" cl-flex-none \",[30,[36,3],[[32,4],\"raised\"],null],\" \",[30,[36,3],[[32,6],\"mini\"],null],\"\\n      \",[30,[36,3],[[32,5],\"active\"],null],\"\\n      \",[30,[36,3],[[32,2],\"bordered\"],null],\" \",[32,0,[\"variant\"]],\" \",[30,[36,3],[[32,3],\"icon-button\"],null]]]],[16,6,[32,7]],[16,\"disabled\",[32,8]],[17,9],[16,4,[30,[36,2],[[32,7],\"button\"],null]],[4,[38,4],[\"click\",[32,0,[\"handleClick\"]]],null]],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[18,10,null],[2,\"\\n\\n\"],[6,[37,3],[[32,0,[\"ripple\"]]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[8,\"cl-ripple\",[[16,0,[31,[\"ripple \",[30,[36,2],[[32,4],[32,0,[\"variant\"]]],null]]]]],[[\"@center\"],[[30,[36,1],[[32,3],[30,[36,0],[[32,2]],null]],null]]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"not\",\"and\",\"unless\",\"if\",\"on\",\"-element\",\"component\",\"let\"]}",
    "meta": {
      "moduleName": "@coachlogix/cl-ui/components/cl-button/template.hbs"
    }
  });
  _exports.default = _default;
});