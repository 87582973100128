define("@coachlogix/cl-ui/components/cl-switch/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "WAotQBY0",
    "block": "{\"symbols\":[\"&attrs\",\"&default\"],\"statements\":[[11,\"div\"],[16,0,[31,[[32,0,[\"styleNamespace\"]],\" \",[30,[36,0],[[32,0,[\"disabled\"]],\"disabled\"],null],\" \",[30,[36,0],[[32,0,[\"value\"]],\"checked\"],null]]]],[24,\"role\",\"checkbox\"],[16,\"aria-checked\",[30,[36,0],[[32,0,[\"value\"]],\"true\",\"false\"],null]],[16,\"tabindex\",[30,[36,0],[[32,0,[\"disabled\"]],\"-1\",\"0\"],null]],[17,1],[4,[38,1],[\"click\",[32,0,[\"handleClick\"]]],null],[4,[38,1],[\"keydown\",[32,0,[\"handleKeydown\"]]],null],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"switch-container\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"track\"],[12],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"thumb\"],[12],[2,\"\\n\"],[6,[37,2],[[32,0,[\"disabled\"]]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[8,\"cl-ripple\",[[24,0,\"ripple-container\"]],[[\"@center\"],[true]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\\n  \"],[10,\"div\"],[12],[2,\"\\n    \"],[18,2,null],[2,\"\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"if\",\"on\",\"unless\"]}",
    "meta": {
      "moduleName": "@coachlogix/cl-ui/components/cl-switch/template.hbs"
    }
  });
  _exports.default = _default;
});