define("ember-power-select/helpers/ember-power-select-is-group", ["exports", "ember-power-select/utils/group-utils"], function (_exports, _groupUtils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.emberPowerSelectIsGroup = emberPowerSelectIsGroup;
  function emberPowerSelectIsGroup([maybeGroup]) {
    return (0, _groupUtils.isGroup)(maybeGroup);
  }
  var _default = Ember.Helper.helper(emberPowerSelectIsGroup);
  _exports.default = _default;
});