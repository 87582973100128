define("ember-animated/-private/ember-scheduler", ["exports", "ember-animated/-private/scheduler", "ember-animated"], function (_exports, _scheduler, _emberAnimated) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.TaskProperty = _exports.Task = void 0;
  _exports.task = task;
  _exports.timeout = timeout;
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function task(taskFn) {
    let tp = _computed(function (propertyName) {
      return new Task(this, taskFn, tp, propertyName);
    });
    Object.setPrototypeOf(tp, TaskProperty.prototype);
    return tp;
  }
  function _computed(fn) {
    if (!true) {
      return Ember.computed(fn);
    }
    let cp = function (proto, key) {
      if (cp.setup !== undefined) {
        cp.setup(proto, key);
      }
      return Ember.computed(fn)(...arguments);
    };
    Ember._setClassicDecorator(cp);
    return cp;
  }
  let handlerCounter = 0;
  let BaseTaskProperty;
  if (true) {
    BaseTaskProperty = class {};
  } else {
    BaseTaskProperty = Ember.ComputedProperty;
  }
  class TaskProperty extends BaseTaskProperty {
    constructor(...args) {
      super(...args);
      _defineProperty(this, "_bufferPolicy", void 0);
      _defineProperty(this, "_observes", void 0);
    }
    restartable() {
      this._bufferPolicy = cancelAllButLast;
      return this;
    }
    drop() {
      this._bufferPolicy = drop;
      return this;
    }
    observes(...deps) {
      this._observes = deps;
      return this;
    }
    setup(proto, taskName) {
      // @ts-ignore: depending on the ember version we may or may not have a super
      // method.
      if (super.setup) {
        // @ts-ignore
        super.setup(...arguments);
      }
      if (this._observes) {
        let handlerName = `_ember_animated_handler_${handlerCounter++}`;
        proto[handlerName] = function () {
          let task = this.get(taskName);
          Ember.run.scheduleOnce('actions', task, '_safePerform');
        };
        for (let i = 0; i < this._observes.length; ++i) {
          let name = this._observes[i];
          Ember.addObserver(proto, name, null, handlerName);
        }
      }
    }
  }
  _exports.TaskProperty = TaskProperty;
  let priv = new WeakMap();
  function getPriv(task) {
    return priv.get(task);
  }
  class Task {
    constructor(context, implementation, taskProperty, name) {
      _defineProperty(this, "concurrency", 0);
      _defineProperty(this, "isRunning", false);
      priv.set(this, {
        context,
        implementation,
        instances: [],
        taskProperty,
        name
      });
    }
    perform(...args) {
      let self = this;
      let privSelf = getPriv(this);
      let context = privSelf.context;
      let implementation = privSelf.implementation;
      let policy = privSelf.taskProperty._bufferPolicy;
      if (context.isDestroyed) {
        throw new Error(`Tried to perform task ${privSelf.name} on an already destroyed object`);
      }
      cleanupOnDestroy(context, this);
      return (0, _scheduler.spawn)(function* () {
        if (true /* DEBUG */) {
          (0, _scheduler.logErrors)(error => {
            (0, _emberAnimated.microwait)().then(() => {
              throw error;
            });
          });
        }
        try {
          self._addInstance((0, _scheduler.current)());
          if (policy) {
            let maybeWait = policy(self, privSelf);
            if (maybeWait) {
              yield maybeWait;
            }
          }
          let finalValue = yield* withRunLoop(implementation.call(context, ...args));
          return finalValue;
        } finally {
          Ember.run.join(() => {
            self._removeInstance((0, _scheduler.current)());
          });
        }
      });
    }
    cancelAll() {
      getPriv(this).instances.forEach(i => (0, _scheduler.stop)(i));
    }
    _addInstance(i) {
      getPriv(this).instances.push(i);
      Ember.set(this, 'isRunning', true);
      Ember.set(this, 'concurrency', this.concurrency + 1);
    }
    _removeInstance(i) {
      let instances = getPriv(this).instances;
      instances.splice(instances.indexOf(i), 1);
      Ember.set(this, 'concurrency', this.concurrency - 1);
      Ember.set(this, 'isRunning', this.concurrency > 0);
    }
    _safePerform() {
      let {
        context
      } = getPriv(this);
      if (!context.isDestroyed) {
        this.perform();
      }
    }
  }

  // cribbed from machty's ember-concurrency
  _exports.Task = Task;
  function cleanupOnDestroy(owner, object) {
    if (!owner.willDestroy) {
      // we're running in non Ember object (possibly in a test mock)
      return;
    }
    if (!owner.willDestroy.__ember_processes_destroyers__) {
      let oldWillDestroy = owner.willDestroy;
      let disposers = [];
      owner.willDestroy = function () {
        for (let i = 0, l = disposers.length; i < l; i++) {
          disposers[i]();
        }
        oldWillDestroy.apply(owner, arguments);
      };
      /* eslint-disable-next-line @typescript-eslint/camelcase */
      owner.willDestroy.__ember_processes_destroyers__ = disposers;
    }
    owner.willDestroy.__ember_processes_destroyers__.push(() => {
      try {
        object.cancelAll();
      } catch (err) {
        if (err.message !== 'TaskCancelation') {
          throw err;
        }
      }
    });
  }
  function cancelAllButLast(_task, privTask) {
    let instances = privTask.instances;
    for (let i = 0; i < instances.length - 1; i++) {
      (0, _scheduler.stop)(instances[i]);
    }
  }
  function drop(_task, privTask) {
    let instances = privTask.instances;
    for (let i = 1; i < instances.length; i++) {
      (0, _scheduler.stop)(instances[i]);
    }
  }
  function* withRunLoop(generator) {
    let state;
    let threw;
    let nextValue;
    let fulfilled = true;
    while (true) {
      Ember.run.join(() => {
        try {
          if (fulfilled) {
            state = generator.next(nextValue);
          } else {
            state = generator.throw(nextValue);
          }
        } catch (err) {
          threw = err;
        }
      });
      if (threw) {
        throw threw;
      }
      if (state.done) {
        return state.value;
      }
      try {
        nextValue = yield state.value;
        fulfilled = true;
      } catch (err) {
        nextValue = err;
        fulfilled = false;
      }
    }
  }
  function timeout(ms) {
    return new Ember.RSVP.Promise(resolve => setTimeout(resolve, ms));
  }
});