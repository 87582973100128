define("@coachlogix/cl-ui/components/cl-user-avatar/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "7N5sMXzq",
    "block": "{\"symbols\":[\"@class\",\"@lastName\",\"@firstName\",\"&attrs\",\"@imageUrl\",\"&default\"],\"statements\":[[11,\"div\"],[16,0,[31,[[32,0,[\"styleNamespace\"]],\" cl-flex-none cl-relative \",[32,1]]]],[16,5,[32,0,[\"style\"]]],[24,\"role\",\"img\"],[16,\"aria-label\",[31,[[32,3],\" \",[32,2]]]],[17,4],[12],[2,\"\\n\"],[6,[37,0],[[32,5]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[1,[32,0,[\"initials\"]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n  \"],[18,6,[[30,[36,2],null,[[\"status-indicator\"],[[30,[36,1],[\"cl-status-indicator\"],[[\"class\",\"size\"],[\"cl-absolute status cl-rounded-full\",[32,0,[\"statusSize\"]]]]]]]]]],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"unless\",\"component\",\"hash\"]}",
    "meta": {
      "moduleName": "@coachlogix/cl-ui/components/cl-user-avatar/template.hbs"
    }
  });
  _exports.default = _default;
});