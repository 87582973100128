define("@coachlogix/cl-ui/components/cl-dialog/component", ["exports", "@ember-decorators/component", "@coachlogix/cl-ui/components/cl-dialog/template"], function (_exports, _component, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _class;
  let ClDialog = (_dec = (0, _component.tagName)(''), _dec2 = (0, _component.layout)(_template.default), _dec(_class = _dec2(_class = class ClDialog extends Ember.Component {
    addDestroyedElementClone(original, clone) {
      original.parentNode.appendChild(clone);
    }
  }) || _class) || _class);
  var _default = ClDialog;
  _exports.default = _default;
});