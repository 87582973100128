define("ember-yeti-table/components/yeti-table/thead/component", ["exports", "@ember-decorators/component", "ember-yeti-table/components/yeti-table/thead/template"], function (_exports, _component, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _class;
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  /**
    Renders a `<thead>` element and yields the row component.
  
    ```hbs
    <table.thead as |head|>
      <head.row as |row|>
        <row.column @prop="firstName" as |column|>
          First name
          {{if column.isAscSorted "(sorted asc)"}}
          {{if column.isDescSorted "(sorted desc)"}}
        </row.column>
      </head.row>
    </table.thead>
    ```
  
    @yield {object} head
    @yield {Component} head.row
  */
  let THead = (_dec = (0, _component.tagName)(''), _dec2 = (0, _component.layout)(_template.default), _dec(_class = _dec2(_class = class THead extends Ember.Component {
    constructor(...args) {
      super(...args);
      _defineProperty(this, "theme", void 0);
      _defineProperty(this, "sortable", void 0);
      _defineProperty(this, "sortSequence", void 0);
      _defineProperty(this, "parent", void 0);
      _defineProperty(this, "columns", void 0);
      _defineProperty(this, "onColumnClick", void 0);
      _defineProperty(this, "shouldDeprecate", void 0);
    }
    /**
     * Used by the old head yield to indicate deprecation
     */
    init() {
      super.init(...arguments);
      (true && !(!this.shouldDeprecate) && Ember.deprecate('The yielded `head` component has been deprecated. Please use `thead` instead.', !this.shouldDeprecate, {
        id: 'ember-yeti-table:head-component',
        until: '2.0.0',
        for: 'ember-yeti-table',
        since: {
          enable: '1.4.0'
        }
      }));
    }
  }) || _class) || _class);
  var _default = THead;
  _exports.default = _default;
});