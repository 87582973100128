define("@coachlogix/cl-ui/components/cl-truncate/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "G6MpEXjp",
    "block": "{\"symbols\":[\"@showLessLabel\",\"@readMoreLabel\",\"@expandable\",\"@collapsible\",\"&attrs\",\"@text\"],\"statements\":[[11,\"span\"],[16,0,[32,0,[\"styleNamespace\"]]],[17,5],[12],[2,\"\\n  \"],[11,\"span\"],[24,0,\"text\"],[4,[38,5],[[32,0,[\"setupClamp\"]],[32,6]],null],[4,[38,6],[[32,0,[\"setupClamp\"]],[32,6]],null],[12],[13],[2,\"\\n\\n\"],[6,[37,2],[[32,0,[\"truncated\"]]],null,[[\"default\"],[{\"statements\":[[6,[37,2],[[30,[36,4],[[32,3],[30,[36,3],[[32,0,[\"expanded\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[11,\"button\"],[24,0,\"read-more-button cl-font-bold cl-uppercase cl-inline-block cl-bg-transparent cl-border-0 cl-p-0 cl-cursor-pointer\"],[24,4,\"button\"],[4,[38,0],[\"click\",[32,0,[\"reveal\"]]],null],[12],[2,\"\\n        \"],[1,[30,[36,2],[[32,2],[32,2],[30,[36,1],[\"cl-truncate.read-more\"],null]],null]],[2,\"\\n      \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[6,[37,2],[[30,[36,4],[[32,4],[32,0,[\"expanded\"]]],null]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[11,\"button\"],[24,0,\"read-more-button cl-font-bold cl-uppercase cl-inline-block cl-bg-transparent cl-border-0 cl-p-0 cl-cursor-pointer\"],[24,4,\"button\"],[4,[38,0],[\"click\",[32,0,[\"collapse\"]]],null],[12],[2,\"\\n        \"],[1,[30,[36,2],[[32,1],[32,1],[30,[36,1],[\"cl-truncate.show-less\"],null]],null]],[2,\"\\n      \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[13],[2,\"\\n\\n\"]],\"hasEval\":false,\"upvars\":[\"on\",\"t\",\"if\",\"not\",\"and\",\"did-insert\",\"did-update\"]}",
    "meta": {
      "moduleName": "@coachlogix/cl-ui/components/cl-truncate/template.hbs"
    }
  });
  _exports.default = _default;
});