define("ember-modifier/-private/class/modifier-manager", ["exports", "ember-modifier/-private/compat"], function (_exports, _compat) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function destroyModifier(modifier) {
    modifier.willRemove();
    modifier.willDestroy();
  }
  class ClassBasedModifierManager {
    constructor(owner) {
      this.owner = owner;
      _defineProperty(this, "capabilities", Ember._modifierManagerCapabilities(false ? '3.22' : '3.13'));
    }
    createModifier(factoryOrClass, args) {
      const Modifier = (0, _compat.isFactory)(factoryOrClass) ? factoryOrClass.class : factoryOrClass;
      const modifier = new Modifier(this.owner, args);
      Ember._registerDestructor(modifier, destroyModifier);
      return modifier;
    }
    installModifier(instance, element, args) {
      instance.element = element;
      if (false) {
        (0, _compat.consumeArgs)(args);
      }
      instance.didReceiveArguments();
      instance.didInstall();
    }
    updateModifier(instance, args) {
      // TODO: this should be an args proxy
      Ember.set(instance, 'args', args);
      if (false) {
        (0, _compat.consumeArgs)(args);
      }
      instance.didUpdateArguments();
      instance.didReceiveArguments();
    }
    destroyModifier(instance) {
      Ember.destroy(instance);
    }
  }
  _exports.default = ClassBasedModifierManager;
});