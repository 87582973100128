define("@coachlogix/cl-ui/components/cl-dialog/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "BTSGHuhP",
    "block": "{\"symbols\":[\"&attrs\",\"&default\"],\"statements\":[[6,[37,5],[[30,[36,4],[[30,[36,3],[\"cl-wormhole\"],null]],null]],[[\"guid\",\"insertBefore\"],[\"%cursor:0%\",null]],[[\"default\"],[{\"statements\":[[2,\"  \"],[8,\"transition-group\",[],[[\"@addDestroyedElementClone\",\"@transitionName\",\"@class\"],[[32,0,[\"addDestroyedElementClone\"]],\"ng\",[30,[36,0],[[32,0,[\"styleNamespace\"]],\"__container cl-flex cl-items-center cl-justify-center\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"cl-card\",[[24,0,\"dialog-card cl-flex cl-flex-col\"],[17,1]],[[\"@raised\"],[true]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[18,2,[[30,[36,2],null,[[\"header\",\"content\",\"actions\"],[[30,[36,1],[\"cl-dialog/header\"],null],[30,[36,1],[\"cl-dialog/content\"],null],[30,[36,1],[\"cl-dialog/actions\"],null]]]]]],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n  \"],[8,\"transition-group\",[],[[\"@transitionName\",\"@class\"],[\"ng\",[30,[36,0],[[32,0,[\"styleNamespace\"]],\"__backdrop\"],null]]],null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"concat\",\"component\",\"hash\",\"get-element-by-id\",\"-clear-element\",\"in-element\"]}",
    "meta": {
      "moduleName": "@coachlogix/cl-ui/components/cl-dialog/template.hbs"
    }
  });
  _exports.default = _default;
});