define("@coachlogix/cl-ui/components/cl-list/subheader/component", ["exports", "@ember-decorators/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class;
  let ClListSubheader = (_dec = (0, _component.tagName)('li'), _dec(_class = class ClListSubheader extends Ember.Component {}) || _class);
  var _default = ClListSubheader;
  _exports.default = _default;
});