define("@coachlogix/cl-ui/components/cl-places-autocomplete/index", ["exports", "@glimmer/component", "ember-concurrency", "ember-concurrency-decorators"], function (_exports, _component, _emberConcurrency, _emberConcurrencyDecorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class, _descriptor;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <ClSelect
    @label={{@label}}
    @placeholder={{@placeholder}}
    @searchPlaceholder={{@searchPlaceholder}}
    @searchEnabled={{true}}
    @loadingMessage={{or @loadingMessage (t "cl-places-autocomplete.loading-message")}}
    @searchMessage={{or @searchMessage (t "cl-places-autocomplete.search-message")}}
    @search={{perform this.searchPlaces}}
    @allowClear={{true}}
    @selected={{@selected}}
    @onChange={{perform this.handleChange}}
    @solid={{@solid}}
    @editable={{@editable}}
    @renderInPlace={{@renderInPlace}}
    ...attributes as |place|>
    {{#if (has-block)}}
      {{yield place}}
    {{else}}
      {{or place.description place.formatted_address place}}
    {{/if}}
  </ClSelect>
  */
  {"id":"SoulwBor","block":"{\"symbols\":[\"place\",\"&default\",\"@label\",\"@placeholder\",\"@searchPlaceholder\",\"@loadingMessage\",\"@searchMessage\",\"@selected\",\"@solid\",\"@editable\",\"@renderInPlace\",\"&attrs\"],\"statements\":[[8,\"cl-select\",[[17,12]],[[\"@label\",\"@placeholder\",\"@searchPlaceholder\",\"@searchEnabled\",\"@loadingMessage\",\"@searchMessage\",\"@search\",\"@allowClear\",\"@selected\",\"@onChange\",\"@solid\",\"@editable\",\"@renderInPlace\"],[[32,3],[32,4],[32,5],true,[30,[36,0],[[32,6],[30,[36,1],[\"cl-places-autocomplete.loading-message\"],null]],null],[30,[36,0],[[32,7],[30,[36,1],[\"cl-places-autocomplete.search-message\"],null]],null],[30,[36,2],[[32,0,[\"searchPlaces\"]]],null],true,[32,8],[30,[36,2],[[32,0,[\"handleChange\"]]],null],[32,9],[32,10],[32,11]]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,3],[[27,[32,2]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[18,2,[[32,1]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[1,[30,[36,0],[[32,1,[\"description\"]],[32,1,[\"formatted_address\"]],[32,1]],null]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"or\",\"t\",\"perform\",\"if\"]}","meta":{"moduleName":"@coachlogix/cl-ui/components/cl-places-autocomplete/index.hbs"}});
  let ClPlacesAutocompleteComponent = (_dec = Ember.inject.service, (_class = class ClPlacesAutocompleteComponent extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "places", _descriptor, this);
      _defineProperty(this, "sessionToken", void 0);
      this.initSession.perform();
    }
    *initSession() {
      yield this.places.initPlaces.perform();
      this.sessionToken = yield this.places.getSessionToken.perform();
    }
    *searchPlaces(term) {
      yield (0, _emberConcurrency.timeout)(1500);
      return yield this.places.getPlacePredictions.perform({
        input: term,
        sessionToken: this.sessionToken,
        types: this.args.types
      });
    }
    *handleChange(place) {
      if (Ember.isPresent(place)) {
        let detailedPlace = yield this.places.getDetails.perform({
          placeId: place.place_id,
          sessionToken: this.sessionToken
        });

        // this session has ended. We need to get a new token
        // to have the autocomplete ready for the next session
        this.sessionToken = yield this.places.getSessionToken.perform();
        this.args.onChange(detailedPlace);
      } else {
        this.args.onChange(null);
      }
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "places", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "initSession", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "initSession"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "searchPlaces", [_emberConcurrencyDecorators.restartableTask], Object.getOwnPropertyDescriptor(_class.prototype, "searchPlaces"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "handleChange", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "handleChange"), _class.prototype)), _class));
  _exports.default = ClPlacesAutocompleteComponent;
  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, ClPlacesAutocompleteComponent);
});