define("@coachlogix/cl-ui/components/cl-icon/label/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "vch/r+Tg",
    "block": "{\"symbols\":[\"&attrs\",\"&default\"],\"statements\":[[11,\"div\"],[16,0,[31,[\"cl-bg-\",[32,0,[\"variant\"]],\" cl-text-\",[32,0,[\"variant\"]],\"-contrast cl-absolute cl-font-heading cl-uppercase cl-font-medium cl-text-center cl-rounded-sm \",[32,0,[\"styleNamespace\"]]]]],[16,5,[32,0,[\"style\"]]],[17,1],[12],[2,\"\\n  \"],[18,2,null],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "@coachlogix/cl-ui/components/cl-icon/label/template.hbs"
    }
  });
  _exports.default = _default;
});