define("@coachlogix/cl-ui/macros/theme-variant", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = computedThemeVariant;
  const THEME_CLASSES = ['primary', 'secondary', 'success', 'info', 'warn', 'danger'];
  function computedThemeVariant(defaultVariant = '') {
    return Ember.computed(...THEME_CLASSES, function () {
      for (let c of THEME_CLASSES) {
        if (Ember.get(this, c)) {
          return c;
        }
      }
      return defaultVariant;
    });
  }
});