define("@coachlogix/cl-ui/components/cl-chart/component", ["exports", "@ember-decorators/component", "@coachlogix/cl-ui/components/cl-chart/template"], function (_exports, _component, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _class, _class2, _temp;
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  let ClChart = (_dec = (0, _component.layout)(_template.default), _dec2 = (0, _component.tagName)(''), _dec3 = Ember.computed('values.@each.{tooltipEl,value,variant}'), _dec4 = Ember.computed('defaultOptions', 'options'), _dec5 = Ember._action, _dec6 = Ember._action, _dec7 = Ember._action, _dec(_class = _dec2(_class = (_class2 = (_temp = class ClChart extends Ember.Component {
    constructor(...args) {
      super(...args);
      _defineProperty(this, "values", Ember.A());
      _defineProperty(this, "defaultOptions", {});
    }
    get data() {
      return {
        labels: this.values.map(v => v.tooltipEl.textContent.trim()),
        series: this.values.map(v => ({
          value: v.value,
          className: v.variant ? `ct-series-${v.variant}` : null,
          name: v.tooltipEl.textContent.trim()
        }))
      };
    }
    get chartOptions() {
      return {
        ...this.defaultOptions,
        ...this.options
      };
    }
    updateValues() {
      let currentPercentage = 0;
      let total = this.values.reduce((acc, b) => acc + b.value, 0);
      for (let i = 0; i < this.values.length; i++) {
        let value = this.values.objectAt(i);
        let percent = value.value / total * 100;
        let remainingPercent = 100 - percent;
        let offset = (100 - currentPercentage + 25) % 100;
        currentPercentage += percent;
        Ember.set(value, 'percent', percent);
        Ember.set(value, 'remainingPercent', remainingPercent);
        Ember.set(value, 'offset', offset);
      }
    }
    addValue(value) {
      this.values.addObject(value);
      this.updateValues();
    }
    removeValue(value) {
      this.values.removeObject(value);
      this.updateValues();
    }
    updateValue() {
      this.updateValues();
    }
  }, _temp), (_applyDecoratedDescriptor(_class2.prototype, "data", [_dec3], Object.getOwnPropertyDescriptor(_class2.prototype, "data"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "chartOptions", [_dec4], Object.getOwnPropertyDescriptor(_class2.prototype, "chartOptions"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "addValue", [_dec5], Object.getOwnPropertyDescriptor(_class2.prototype, "addValue"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "removeValue", [_dec6], Object.getOwnPropertyDescriptor(_class2.prototype, "removeValue"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "updateValue", [_dec7], Object.getOwnPropertyDescriptor(_class2.prototype, "updateValue"), _class2.prototype)), _class2)) || _class) || _class);
  var _default = ClChart;
  _exports.default = _default;
});