define("@coachlogix/cl-ui/components/cl-list/item/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "GCTqwBsw",
    "block": "{\"symbols\":[\"@dense\",\"&default\",\"@onClick\",\"@href\",\"@target\",\"@rel\",\"@download\",\"@isActive\",\"@bordered\",\"@class\",\"&attrs\"],\"statements\":[[11,\"li\"],[16,0,[31,[[32,0,[\"styleNamespace\"]],\" \",[32,10],\" cl-flex-shrink-0 cl-flex cl-relative\\n  \",[30,[36,0],[[32,9],\"bordered\"],null],\" \",[30,[36,0],[[32,8],\"active\"],null]]]],[17,11],[12],[2,\"\\n\\n\"],[6,[37,0],[[32,4]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[11,\"a\"],[16,0,[31,[\"clickable cl-flex-1 cl-flex cl-items-center \",[30,[36,0],[[32,1],\"cl-px-3 cl-py-1\",\"cl-px-4 cl-py-3\"],null],\" cl-no-underline cl-cursor-pointer cl-overflow-hidden\"]]],[16,6,[32,4]],[16,\"target\",[32,5]],[16,\"rel\",[32,6]],[16,\"download\",[32,7]],[4,[38,1],[\"click\",[32,0,[\"didClick\"]]],null],[12],[2,\"\\n      \"],[18,2,null],[2,\"\\n    \"],[13],[2,\"\\n    \"],[8,\"cl-ripple\",[],[[],[]],null],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,0],[[32,3]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[11,\"button\"],[16,0,[31,[\"clickable cl-flex-1 cl-flex cl-items-center \",[30,[36,0],[[32,1],\"cl-px-3 cl-py-1\",\"cl-px-4 cl-py-3\"],null],\" cl-text-left cl-border-0 cl-cursor-pointer cl-overflow-hidden\"]]],[24,4,\"button\"],[4,[38,1],[\"click\",[32,0,[\"didClick\"]]],null],[12],[2,\"\\n      \"],[18,2,null],[2,\"\\n    \"],[13],[2,\"\\n    \"],[8,\"cl-ripple\",[],[[],[]],null],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[10,\"div\"],[15,0,[31,[\"cl-flex-1 cl-flex cl-items-center \",[30,[36,0],[[32,1],\"cl-px-3 cl-py-1\",\"cl-px-4 cl-py-3\"],null],\" cl-overflow-hidden\"]]],[12],[2,\"\\n      \"],[18,2,null],[2,\"\\n    \"],[13],[2,\"\\n  \"]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"if\",\"on\"]}",
    "meta": {
      "moduleName": "@coachlogix/cl-ui/components/cl-list/item/template.hbs"
    }
  });
  _exports.default = _default;
});