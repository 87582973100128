define("@coachlogix/cl-ui/components/cl-empty-state/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "oarpQrJa",
    "block": "{\"symbols\":[\"&default\",\"@icon\",\"@text\"],\"statements\":[[10,\"div\"],[14,0,\"cl-justify-center cl-items-center cl-flex cl-flex-col cl-text-light-grey-blue\"],[12],[2,\"\\n\"],[6,[37,0],[[32,2]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[8,\"cl-icon\",[],[[\"@size\",\"@icon\"],[[32,0,[\"size\"]],[32,2]]],null],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[18,1,null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[10,\"p\"],[14,0,\"cl-title-5\"],[12],[1,[32,3]],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"if\"]}",
    "meta": {
      "moduleName": "@coachlogix/cl-ui/components/cl-empty-state/template.hbs"
    }
  });
  _exports.default = _default;
});