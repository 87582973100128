define("@coachlogix/cl-ui/components/cl-dropdown/component", ["exports", "@ember-decorators/component", "@coachlogix/cl-ui/utils/calculate-position", "@coachlogix/cl-ui/components/cl-dropdown/template"], function (_exports, _component, _calculatePosition, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _class2, _temp;
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  let ClDropdown = (_dec = (0, _component.tagName)(''), _dec2 = (0, _component.layout)(_template.default), _dec3 = Ember._action, _dec4 = Ember._action, _dec5 = Ember._action, _dec(_class = _dec2(_class = (_class2 = (_temp = class ClDropdown extends Ember.Component {
    constructor(...args) {
      super(...args);
      _defineProperty(this, "defaultCalculatePosition", _calculatePosition.default);
      _defineProperty(this, "hoverMode", false);
    }
    prevent(e) {
      if (!this.hoverMode) {
        return;
      }
      return e.stopImmediatePropagation();
    }
    open(dropdown) {
      if (!this.hoverMode) {
        return;
      }
      if (this.closeTimer) {
        Ember.run.cancel(this.closeTimer);
        this.closeTimer = null;
      } else {
        dropdown.actions.open();
      }
    }
    closeLater(dropdown) {
      if (!this.hoverMode) {
        return;
      }
      this.closeTimer = Ember.run.later(() => {
        this.closeTimer = null;
        dropdown.actions.close();
      }, 200);
    }
  }, _temp), (_applyDecoratedDescriptor(_class2.prototype, "prevent", [_dec3], Object.getOwnPropertyDescriptor(_class2.prototype, "prevent"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "open", [_dec4], Object.getOwnPropertyDescriptor(_class2.prototype, "open"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "closeLater", [_dec5], Object.getOwnPropertyDescriptor(_class2.prototype, "closeLater"), _class2.prototype)), _class2)) || _class) || _class);
  var _default = ClDropdown;
  _exports.default = _default;
});