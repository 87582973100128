define("@coachlogix/cl-ui/components/cl-chart/value/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "6AUffHG8",
    "block": "{\"symbols\":[\"&default\",\"@color\",\"@value\"],\"statements\":[[1,[30,[36,2],[[32,0,[\"onDidUpdate\"]],[32,3],[32,2]],null]],[2,\"\\n\"],[1,[30,[36,3],[[32,0,[\"onWillDestroy\"]]],null]],[2,\"\\n\\n\"],[6,[37,4],[[27,[32,1]]],null,[[\"default\"],[{\"statements\":[[6,[37,1],[[30,[36,0],[[32,0,[\"tooltipEl\"]]],null]],[[\"guid\",\"insertBefore\"],[\"%cursor:0%\",null]],[[\"default\"],[{\"statements\":[[2,\"    \"],[18,1,null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"-clear-element\",\"in-element\",\"did-update\",\"will-destroy\",\"if\"]}",
    "meta": {
      "moduleName": "@coachlogix/cl-ui/components/cl-chart/value/template.hbs"
    }
  });
  _exports.default = _default;
});