define("@coachlogix/cl-ui/components/cl-dropdown/content/component", ["exports", "@ember-decorators/component", "@coachlogix/cl-ui/components/cl-dropdown/content/template"], function (_exports, _component, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _class;
  let ClDropdownContent = (_dec = (0, _component.tagName)(''), _dec2 = (0, _component.layout)(_template.default), _dec(_class = _dec2(_class = class ClDropdownContent extends Ember.Component {}) || _class) || _class);
  var _default = ClDropdownContent;
  _exports.default = _default;
});