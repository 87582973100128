define("@coachlogix/cl-ui/components/cl-icon/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "tEfqHwP2",
    "block": "{\"symbols\":[\"@class\",\"@bordered\",\"@solid\",\"&attrs\",\"@icon\",\"&default\"],\"statements\":[[11,\"i\"],[16,0,[31,[\"material-icons \",[32,0,[\"styleNamespace\"]],\" \",[30,[36,0],[[32,3],\"solid\"],null],\" \",[30,[36,0],[[32,2],\"bordered\"],null],\" \",[32,0,[\"variant\"]],\" cl-relative \",[32,1]]]],[16,5,[32,0,[\"style\"]]],[17,4],[12],[2,\"\\n  \"],[1,[30,[36,1],[[32,5]],null]],[2,\"\\n  \"],[18,6,[[30,[36,3],null,[[\"label\"],[[30,[36,2],[\"cl-icon/label\"],[[\"iconSize\"],[[32,0,[\"size\"]]]]]]]]]],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"if\",\"underscore\",\"component\",\"hash\"]}",
    "meta": {
      "moduleName": "@coachlogix/cl-ui/components/cl-icon/template.hbs"
    }
  });
  _exports.default = _default;
});