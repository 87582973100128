define("ember-yeti-table/components/yeti-table/thead/row/column/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "GOjDM0U5",
    "block": "{\"symbols\":[\"@theme\",\"@class\",\"&attrs\",\"@onClick\",\"&default\"],\"statements\":[[6,[37,0],[[32,0,[\"visible\"]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[11,\"th\"],[16,\"role\",[30,[36,0],[[32,0,[\"sortable\"]],\"button\"],null]],[16,0,[31,[[32,2],\" \",[32,1,[\"theadCell\"]],\" \",[30,[36,0],[[32,0,[\"sortable\"]],[32,1,[\"sorting\",\"columnSortable\"]]],null],\" \",[30,[36,0],[[32,0,[\"isSorted\"]],[32,1,[\"sorting\",\"columnSorted\"]]],null],\" \",[30,[36,0],[[32,0,[\"isAscSorted\"]],[32,1,[\"sorting\",\"columnSortedAsc\"]]],null],\" \",[30,[36,0],[[32,0,[\"isDescSorted\"]],[32,1,[\"sorting\",\"columnSortedDesc\"]]],null]]]],[17,3],[4,[38,2],[\"click\",[30,[36,0],[[32,0,[\"sortable\"]],[30,[36,1],[[32,4],[32,0]],null],[32,0,[\"noop\"]]],null]],null],[4,[38,3],[[32,0,[\"updateName\"]]],null],[12],[2,\"\\n    \"],[18,5,[[30,[36,4],null,[[\"isSorted\",\"isAscSorted\",\"isDescSorted\"],[[32,0,[\"isSorted\"]],[32,0,[\"isAscSorted\"]],[32,0,[\"isDescSorted\"]]]]]]],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"if\",\"fn\",\"on\",\"did-insert\",\"hash\"]}",
    "meta": {
      "moduleName": "ember-yeti-table/components/yeti-table/thead/row/column/template.hbs"
    }
  });
  _exports.default = _default;
});