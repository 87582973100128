define("@coachlogix/cl-ui/components/cl-menu/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "+xkoLFo7",
    "block": "{\"symbols\":[\"dd\",\"@disabled\",\"@calculatePosition\",\"@horizontalPosition\",\"@verticalPosition\",\"@matchTriggerWidth\",\"@preventScroll\",\"@renderInPlace\",\"@initiallyOpened\",\"@onOpen\",\"@onClose\",\"&attrs\",\"&default\"],\"statements\":[[8,\"cl-dropdown\",[[17,12]],[[\"@disabled\",\"@calculatePosition\",\"@horizontalPosition\",\"@verticalPosition\",\"@matchTriggerWidth\",\"@preventScroll\",\"@renderInPlace\",\"@initiallyOpened\",\"@onOpen\",\"@onClose\"],[[32,2],[32,3],[32,4],[32,5],[32,6],[32,7],[32,8],[32,9],[32,10],[32,11]]],[[\"default\"],[{\"statements\":[[2,\"\\n\\n  \"],[18,13,[[30,[36,1],null,[[\"trigger\",\"content\",\"actions\",\"disabled\",\"isOpen\",\"uniqueId\"],[[32,1,[\"trigger\"]],[30,[36,0],[\"cl-menu/content\"],[[\"dropdownContent\",\"ddActions\"],[[32,1,[\"content\"]],[32,1,[\"actions\"]]]]],[32,1,[\"actions\"]],[32,1,[\"disabled\"]],[32,1,[\"isOpen\"]],[32,1,[\"uniqueId\"]]]]]]],[2,\"\\n\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"component\",\"hash\"]}",
    "meta": {
      "moduleName": "@coachlogix/cl-ui/components/cl-menu/template.hbs"
    }
  });
  _exports.default = _default;
});