define("@coachlogix/cl-ui/components/cl-alert/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "38AT10/d",
    "block": "{\"symbols\":[\"&attrs\",\"&default\"],\"statements\":[[11,\"div\"],[16,0,[31,[\"cl-bg-\",[32,0,[\"variant\"]],\" cl-text-\",[32,0,[\"variant\"]],\"-contrast cl-px-2\"]]],[17,1],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"cl-container cl-mx-auto cl-flex cl-items-center cl-justify-center cl-space-x-2\"],[12],[2,\"\\n    \"],[18,2,[[30,[36,1],null,[[\"icon\",\"text\",\"button\"],[[30,[36,0],[\"cl-icon\"],null],[30,[36,0],[\"cl-alert/text\"],[[\"class\"],[\"cl-py-1 cl-flex-1\"]]],[30,[36,0],[\"cl-button\"],null]]]]]],[2,\"\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"component\",\"hash\"]}",
    "meta": {
      "moduleName": "@coachlogix/cl-ui/components/cl-alert/template.hbs"
    }
  });
  _exports.default = _default;
});