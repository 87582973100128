define("@coachlogix/cl-ui/components/cl-calendar/nav/component", ["exports", "@ember-decorators/component", "@coachlogix/cl-ui/components/cl-calendar/nav/template"], function (_exports, _component, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _class, _class2;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  let ClCalendarNav = (_dec = (0, _component.tagName)(''), _dec2 = (0, _component.layout)(_template.default), _dec3 = Ember._action, _dec4 = Ember.computed('mode'), _dec5 = Ember._action, _dec6 = Ember.computed('mode'), _dec7 = Ember.computed('mode'), _dec(_class = _dec2(_class = (_class2 = class ClCalendarNav extends Ember.Component {
    goToToday(event) {
      let today = new Date();
      this.onModeChange('month');
      this.calendar.actions.changeCenter(today, this.calendar, event);
    }
    get unit() {
      return this.mode === 'decade' ? 'year' : 'month';
    }
    changeMode() {
      let modeToChangeTo = this.mode === 'decade' ? 'month' : 'decade';
      this.onModeChange(modeToChangeTo);
    }
    get offset() {
      return this.mode === 'decade' ? 12 : 1;
    }
    get negativeOffset() {
      return this.mode === 'decade' ? -12 : -1;
    }
  }, (_applyDecoratedDescriptor(_class2.prototype, "goToToday", [_dec3], Object.getOwnPropertyDescriptor(_class2.prototype, "goToToday"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "unit", [_dec4], Object.getOwnPropertyDescriptor(_class2.prototype, "unit"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "changeMode", [_dec5], Object.getOwnPropertyDescriptor(_class2.prototype, "changeMode"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "offset", [_dec6], Object.getOwnPropertyDescriptor(_class2.prototype, "offset"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "negativeOffset", [_dec7], Object.getOwnPropertyDescriptor(_class2.prototype, "negativeOffset"), _class2.prototype)), _class2)) || _class) || _class);
  var _default = ClCalendarNav;
  _exports.default = _default;
});