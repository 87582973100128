define("ember-animated/templates/components/animated-container", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "4C0Hr0Nr",
    "block": "{\"symbols\":[\"Tag\",\"@class\",\"&attrs\",\"&default\"],\"statements\":[[6,[37,2],[[30,[36,1],[[30,[36,0],[[32,0,[\"tag\"]]],null]],[[\"tagName\"],[[32,0,[\"tag\"]]]]]],null,[[\"default\"],[{\"statements\":[[8,[32,1],[[16,0,[31,[\"animated-container \",[32,2]]]],[17,3]],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[18,4,null],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"-element\",\"component\",\"let\"]}",
    "meta": {
      "moduleName": "ember-animated/templates/components/animated-container.hbs"
    }
  });
  _exports.default = _default;
});