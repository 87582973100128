define("@coachlogix/cl-ui/components/cl-chip/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "WHwPEx6n",
    "block": "{\"symbols\":[\"&default\",\"@text\",\"@uppercase\",\"@solid\",\"@danger\",\"@warn\",\"@info\",\"@success\",\"@secondary\",\"@primary\",\"&attrs\"],\"statements\":[[11,\"div\"],[16,5,[32,0,[\"customStyles\"]]],[16,0,[31,[[32,0,[\"styleNamespace\"]],\"\\n    \",[30,[36,0],[[32,10],\"primary\"],null],\"\\n    \",[30,[36,0],[[32,9],\"secondary\"],null],\"\\n    \",[30,[36,0],[[32,8],\"success\"],null],\"\\n    \",[30,[36,0],[[32,7],\"info\"],null],\"\\n    \",[30,[36,0],[[32,6],\"warn\"],null],\"\\n    \",[30,[36,0],[[32,5],\"danger\"],null],\"\\n    \",[30,[36,0],[[32,4],\"solid\"],null],\"\\n    \",[30,[36,0],[[32,3],\"cl-uppercase\"],null],\"\\n    cl-title-7 cl-font-bold cl-inline-flex cl-justify-center cl-items-center cl-rounded-full\"]]],[17,11],[12],[2,\"\\n\"],[6,[37,0],[[32,2]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[1,[32,2]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[18,1,null],[2,\"\\n\"]],\"parameters\":[]}]]],[13]],\"hasEval\":false,\"upvars\":[\"if\"]}",
    "meta": {
      "moduleName": "@coachlogix/cl-ui/components/cl-chip/template.hbs"
    }
  });
  _exports.default = _default;
});