define("@coachlogix/cl-ui/components/cl-top-bar/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "zzuOycdD",
    "block": "{\"symbols\":[\"&attrs\",\"&default\"],\"statements\":[[11,\"header\"],[16,0,[31,[[32,0,[\"styleNamespace\"]],\" cl-flex cl-flex-col cl-z-10\\n    cl-border-solid cl-border-0 cl-border-b cl-border-transparent\\n    \",[30,[36,1],[[32,0,[\"solid\"]],\"cl-bg-white\"],null],\"\\n    \",[30,[36,1],[[30,[36,2],[[32,0,[\"solid\"]],[32,0,[\"bordered\"]]],null],\"cl-border-pale-grey\"],null],\"\\n    \",[30,[36,1],[[32,0,[\"variant\"]],[30,[36,0],[\"cl-bg-\",[32,0,[\"variant\"]],\" cl-text-\",[32,0,[\"variant\"]],\"-contrast\"],null]],null]]]],[17,1],[12],[2,\"\\n  \"],[18,2,[[30,[36,4],null,[[\"row\"],[[30,[36,3],[\"cl-top-bar/row\"],null]]]]]],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"concat\",\"if\",\"and\",\"component\",\"hash\"]}",
    "meta": {
      "moduleName": "@coachlogix/cl-ui/components/cl-top-bar/template.hbs"
    }
  });
  _exports.default = _default;
});