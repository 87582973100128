define("@coachlogix/cl-ui/components/cl-menu/content/item/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "XOUc3YOn",
    "block": "{\"symbols\":[\"ContentItem\",\"@href\",\"@target\",\"@rel\",\"@disabled\",\"@class\",\"@download\",\"&attrs\",\"&default\",\"@itemComponent\"],\"statements\":[[6,[37,1],[[30,[36,0],[[32,10]],null]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[8,[32,1],[[17,8]],[[\"@onClick\",\"@href\",\"@target\",\"@rel\",\"@disabled\",\"@class\",\"@download\"],[[32,0,[\"handleClick\"]],[32,2],[32,3],[32,4],[32,5],[32,6],[32,7]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[18,9,null],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"component\",\"let\"]}",
    "meta": {
      "moduleName": "@coachlogix/cl-ui/components/cl-menu/content/item/template.hbs"
    }
  });
  _exports.default = _default;
});