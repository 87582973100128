define("ember-filestack/utils/sanitize-transformations", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = sanitizeTransformations;
  function sanitizeTransformations(obj) {
    Object.keys(obj).forEach(key => {
      let value = obj[key];
      let type = typeof value;
      if (type === 'object' && value !== null) {
        sanitizeTransformations(value);
        if (Object.keys(value).length === 0) {
          delete obj[key];
        }
      } else if (Ember.isNone(value)) {
        delete obj[key];
      }
    });
  }
});