define("@coachlogix/cl-ui/components/cl-dialog/content/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "5QJ12843",
    "block": "{\"symbols\":[\"&attrs\",\"&default\"],\"statements\":[[11,\"div\"],[16,0,[31,[[32,0,[\"styleNamespace\"]],\" \",[30,[36,0],[[32,0,[\"padding\"]],\"cl-px-4 cl-py-3\"],null],\" cl-flex-1 cl-overflow-y-auto cl-overflow-x-hidden\"]]],[17,1],[12],[2,\"\\n  \"],[18,2,null],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"if\"]}",
    "meta": {
      "moduleName": "@coachlogix/cl-ui/components/cl-dialog/content/template.hbs"
    }
  });
  _exports.default = _default;
});