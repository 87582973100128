define("@coachlogix/cl-ui/components/cl-markdown-editor/index", ["exports", "@glimmer/component", "ember-component-css/pod-names"], function (_exports, _component, _podNames) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <TuiEditor
    class={{this.styleNamespace}}
    @viewer={{@viewer}}
    @placeholder={{@placeholder}}
    @value={{@value}}
    @onChange={{@onChange}}
    @height={{@height}}
    @editType="wysiwyg"
    @usageStatistics={{false}}
    @toolbarItems={{this.editorToolbarItems}}
    @hooks={{this.hooks}}
    @linkAttribute={{hash target="_blank" rel="noopener"}}
    ...attributes
  />
  */
  {"id":"+PEFK6dt","block":"{\"symbols\":[\"@viewer\",\"@placeholder\",\"@value\",\"@onChange\",\"@height\",\"&attrs\"],\"statements\":[[8,\"tui-editor\",[[16,0,[32,0,[\"styleNamespace\"]]],[17,6]],[[\"@viewer\",\"@placeholder\",\"@value\",\"@onChange\",\"@height\",\"@editType\",\"@usageStatistics\",\"@toolbarItems\",\"@hooks\",\"@linkAttribute\"],[[32,1],[32,2],[32,3],[32,4],[32,5],\"wysiwyg\",false,[32,0,[\"editorToolbarItems\"]],[32,0,[\"hooks\"]],[30,[36,0],null,[[\"target\",\"rel\"],[\"_blank\",\"noopener\"]]]]],null]],\"hasEval\":false,\"upvars\":[\"hash\"]}","meta":{"moduleName":"@coachlogix/cl-ui/components/cl-markdown-editor/index.hbs"}});
  class ClMarkdownEditor extends _component.default {
    constructor(...args) {
      super(...args);
      _defineProperty(this, "styleNamespace", _podNames.default['cl-markdown-editor']);
      _defineProperty(this, "image", true);
    }
    get editorToolbarItems() {
      return ['bold', 'italic', 'strike', 'divider', 'heading', 'hr', 'quote', 'divider', 'ul', 'ol', 'task', 'divider', 'table', this.image ? 'image' : '', 'link'];
    }
  }
  _exports.default = ClMarkdownEditor;
  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, ClMarkdownEditor);
});