define("@coachlogix/cl-ui/services/cl-theme", ["exports", "tinycolor2"], function (_exports, _tinycolor) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class, _descriptor, _temp;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  let ClTheme = (_dec = Ember.inject.service, (_class = (_temp = class ClTheme extends Ember.Service {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "clCustomProperties", _descriptor, this);
      _defineProperty(this, "validThemeNames", ['primary', 'secondary']);
      _defineProperty(this, "validKeys", [...this.validThemeNames, ...this.validThemeNames.map(s => `${s}-contrast`)]);
    }
    setTheme(theme, element) {
      for (let variableName of Object.keys(theme)) {
        if (this.validKeys.includes(variableName)) {
          let variableValue = theme[variableName];
          this.setColor(variableName, variableValue, element);
        } else {
          Ember.debug(`You provided a theme variable of "${variableName}" but that isn't valid. Valid variable names are ${this.validKeys.join(',')}.`);
        }
      }
    }
    clearTheme(element) {
      for (let prop of this.validKeys) {
        this.clCustomProperties.removeVal({
          element,
          variableName: `--${prop}`
        });
        this.clCustomProperties.removeVal({
          element,
          variableName: `--${prop}-rgb`
        });
        if (['primary', 'secondary'].includes(prop)) {
          this.clCustomProperties.removeVal({
            element,
            variableName: `--${prop}-light`
          });
        }
      }
    }
    setColor(name, colorString, element) {
      if (colorString === null) {
        // clear color
        this.clCustomProperties.removeVal({
          element,
          variableName: `--${name}`
        });
        this.clCustomProperties.removeVal({
          element,
          variableName: `--${name}-rgb`
        });
        this.clCustomProperties.removeVal({
          element,
          variableName: `--${name}-contrast`
        });
        this.clCustomProperties.removeVal({
          element,
          variableName: `--${name}-contrast-rgb`
        });
        if (['primary', 'secondary'].includes(name)) {
          this.clCustomProperties.removeVal({
            element,
            variableName: `--${name}-light`
          });
        }
      } else {
        // set color
        let color = (0, _tinycolor.default)(colorString);
        if (color.isValid()) {
          this.clCustomProperties.setVal({
            variableName: `--${name}`,
            variableValue: color.toHexString(),
            element
          });
          let rgbColor = color.toRgb();
          this.clCustomProperties.setVal({
            variableName: `--${name}-rgb`,
            variableValue: `${rgbColor.r}, ${rgbColor.g}, ${rgbColor.b}`,
            element
          });
          let contrastColor = _tinycolor.default.mostReadable(color, ['#757c88', '#ffffff']);
          this.clCustomProperties.setVal({
            variableName: `--${name}-contrast`,
            variableValue: contrastColor.toHexString(),
            element
          });
          let rgbContrastColor = color.toRgb();
          this.clCustomProperties.setVal({
            variableName: `--${name}-contrast-rgb`,
            variableValue: `${rgbContrastColor.r}, ${rgbContrastColor.g}, ${rgbContrastColor.b}`,
            element
          });

          // calculate colors for gradients
          if (['primary', 'secondary'].includes(name)) {
            // let method = color.isDark() ? 'lighten' : 'darken';
            let lightColor = color.lighten(10);
            this.clCustomProperties.setVal({
              variableName: `--${name}-light`,
              variableValue: lightColor.toHexString(),
              element
            });
          }
        } else {
          Ember.debug(`You provided "${colorString}" as the ${name} color, but it isn't a valid color.`);
        }
      }
    }
  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "clCustomProperties", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = ClTheme;
});