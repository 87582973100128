define("@coachlogix/cl-ui/components/cl-selection-dialog/component", ["exports", "@ember-decorators/component", "ember-concurrency", "ember-concurrency-decorators", "@coachlogix/cl-ui/components/cl-selection-dialog/template"], function (_exports, _component, _emberConcurrency, _emberConcurrencyDecorators, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _class, _class2, _temp;
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  let ClSelectionDialog = (_dec = (0, _component.tagName)(''), _dec2 = (0, _component.layout)(_template.default), _dec3 = Ember.computed('filteredOptions', '_options', 'getOptions', 'search'), _dec4 = Ember.computed('_options.[]', 'searchText', 'matcher', 'searchField'), _dec5 = Ember._action, _dec6 = Ember._action, _dec7 = Ember.computed('multiple', '_selected.length', 'min', 'max'), _dec(_class = _dec2(_class = (_class2 = (_temp = class ClSelectionDialog extends Ember.Component {
    constructor(...args) {
      super(...args);
      _defineProperty(this, "multiple", false);
      _defineProperty(this, "searchText", '');
      _defineProperty(this, "searchTextBuffer", '');
      _defineProperty(this, "pageSize", 15);
      _defineProperty(this, "pageNumber", 0);
      _defineProperty(this, "cantLoadMore", false);
      _defineProperty(this, "bordered", false);
      _defineProperty(this, "closeOnSelect", true);
      _defineProperty(this, "matcher", (value, text) => {
        value = value ? value.toUpperCase() : '';
        return value.includes(text.toUpperCase());
      });
    }
    didReceiveAttrs() {
      super.didReceiveAttrs(...arguments);
      this.setup.perform();
    }
    *setup() {
      let options;
      if (this.getOptions) {
        options = yield this.loadPage.perform();
      } else {
        if (typeof this.options === 'function') {
          options = this.options();
        } else {
          options = this.options;
        }
        options = yield options;
        this.set('_options', options || []);
      }
      let selected = yield this.selected;
      this.updateSelection(selected);
    }
    get processedData() {
      if (this.getOptions || !this.search) {
        // skip processing and return raw options if remote data is enabled via `getOptions`
        return this._options;
      } else {
        return this.filteredOptions;
      }
    }
    get filteredOptions() {
      let options = this._options || [];
      return options.filter(option => {
        let values = [option];
        if (this.searchField) {
          let fields = Ember.isArray(this.searchField) ? this.searchField : this.searchField.split(',').map(f => f.trim());
          values = fields.map(f => Ember.get(option, f));
        }
        return values.some(value => this.matcher(value, this.searchText));
      });
    }
    *loadSearch(term) {
      this.set('searchTextBuffer', term);

      // we timeout to debounce the calls
      yield (0, _emberConcurrency.timeout)(350);
      this.set('searchText', term);
      if (this.getOptions) {
        this.set('pageNumber', 0);
        this.set('cantLoadMore', false);
        this.loadPage.cancelAll();
        yield this.loadPage.perform(true);
      }
    }
    *loadPage(reset) {
      this.pageNumber++;
      let items = yield this.getOptions(this.searchText, this.pageNumber, this.pageSize);
      if (!items || !items.length || items.length < this.pageSize) {
        this.set('cantLoadMore', true);
      }
      if (reset) {
        this.set('_options', items);
      } else {
        let options = this._options || [];
        this.set('_options', [...options, ...items]);
      }
    }
    updateSelection(selected) {
      let newSelection;
      if (this.multiple) {
        newSelection = (selected || []).slice(0);
      } else {
        newSelection = selected;
      }
      this.set('_selected', newSelection);
    }
    buildSelection(option, selected) {
      let newSelection = (selected || []).slice(0);
      let idx = newSelection.indexOf(option);
      if (idx > -1) {
        newSelection.splice(idx, 1);
      } else {
        newSelection.push(option);
      }
      return newSelection;
    }
    updateSelected(option, value, ev) {
      if (this.multiple) {
        let newSelection = this.buildSelection(option, this._selected);
        this.set('_selected', newSelection);
      } else {
        this.set('_selected', option);
      }
      ev?.stopPropagation?.();
    }
    close() {
      if (this.onClose) {
        this.onClose();
      }
    }
    *select() {
      if (this.onChange) {
        yield this.onChange(this._selected);
      }
      if (this.closeOnSelect) {
        this.close();
      }
    }
    get isWithinNumberOfOptions() {
      if (this.multiple) {
        let isGreater = true;
        let isLesser = true;
        let selectedLength = this._selected?.length || 0;
        if (Ember.isPresent(this.min)) {
          isGreater = selectedLength >= this.min;
        }
        if (Ember.isPresent(this.max)) {
          isLesser = selectedLength <= this.max;
        }
        return isGreater && isLesser;
      } else {
        return true;
      }
    }
  }, _temp), (_applyDecoratedDescriptor(_class2.prototype, "setup", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class2.prototype, "setup"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "processedData", [_dec3], Object.getOwnPropertyDescriptor(_class2.prototype, "processedData"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "filteredOptions", [_dec4], Object.getOwnPropertyDescriptor(_class2.prototype, "filteredOptions"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "loadSearch", [_emberConcurrencyDecorators.restartableTask], Object.getOwnPropertyDescriptor(_class2.prototype, "loadSearch"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "loadPage", [_emberConcurrencyDecorators.enqueueTask], Object.getOwnPropertyDescriptor(_class2.prototype, "loadPage"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "updateSelected", [_dec5], Object.getOwnPropertyDescriptor(_class2.prototype, "updateSelected"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "close", [_dec6], Object.getOwnPropertyDescriptor(_class2.prototype, "close"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "select", [_emberConcurrencyDecorators.dropTask], Object.getOwnPropertyDescriptor(_class2.prototype, "select"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "isWithinNumberOfOptions", [_dec7], Object.getOwnPropertyDescriptor(_class2.prototype, "isWithinNumberOfOptions"), _class2.prototype)), _class2)) || _class) || _class);
  var _default = ClSelectionDialog;
  _exports.default = _default;
});