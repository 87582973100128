define("@coachlogix/cl-ui/components/cl-top-bar/row/cell/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "z1694eZc",
    "block": "{\"symbols\":[\"&attrs\",\"&default\"],\"statements\":[[11,\"div\"],[16,0,[31,[[32,0,[\"styleNamespace\"]],\" cl-flex cl-px-4 cl-items-center\"]]],[17,1],[12],[2,\"\\n  \"],[18,2,null],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "@coachlogix/cl-ui/components/cl-top-bar/row/cell/template.hbs"
    }
  });
  _exports.default = _default;
});