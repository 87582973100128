define("@coachlogix/cl-ui/components/cl-confirmation-dialog/component", ["exports", "@ember-decorators/component", "ember-concurrency-decorators", "@coachlogix/cl-ui/components/cl-confirmation-dialog/template"], function (_exports, _component, _emberConcurrencyDecorators, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _class, _class2;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  let ClConfirmationDialog = (_dec = (0, _component.tagName)(''), _dec2 = (0, _component.layout)(_template.default), _dec(_class = _dec2(_class = (_class2 = class ClConfirmationDialog extends Ember.Component {
    *handleConfirm() {
      if (this.onConfirm) {
        yield this.onConfirm();
      }
      if (this.onClose) {
        this.onClose();
      }
    }
  }, (_applyDecoratedDescriptor(_class2.prototype, "handleConfirm", [_emberConcurrencyDecorators.dropTask], Object.getOwnPropertyDescriptor(_class2.prototype, "handleConfirm"), _class2.prototype)), _class2)) || _class) || _class);
  var _default = ClConfirmationDialog;
  _exports.default = _default;
});