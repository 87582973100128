define("ember-animated/templates/components/animated-each", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "xFv2eVHA",
    "block": "{\"symbols\":[\"child\",\"&else\",\"&default\"],\"statements\":[[6,[37,1],[[30,[36,0],[[30,[36,0],[[32,0,[\"renderedChildren\"]]],null]],null]],[[\"key\"],[\"id\"]],[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[8,\"ea-list-element\",[],[[\"@child\",\"@elementToChild\"],[[32,1],[32,0,[\"_elementToChild\"]]]],[[\"default\"],[{\"statements\":[[18,3,[[32,1,[\"value\"]],[32,1,[\"index\"]]]]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[1]},{\"statements\":[[18,2,null]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "ember-animated/templates/components/animated-each.hbs"
    }
  });
  _exports.default = _default;
});