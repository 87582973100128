define("@coachlogix/cl-ui/components/cl-sidenav/section/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "CqNrRNtZ",
    "block": "{\"symbols\":[\"@isActive\",\"@route\",\"@icon\",\"@mini\",\"@tooltip\",\"@name\",\"&default\"],\"statements\":[[8,\"cl-sidenav/item\",[[16,0,[30,[36,3],[[30,[36,2],[[30,[36,1],[[32,2],[30,[36,0],[[32,2]],null]],null],[32,1]],null],\"active\"],null]]],[[\"@onClick\",\"@icon\",\"@mini\",\"@tooltip\"],[[32,0,[\"sectionClick\"]],[32,3],[32,4],[32,5]]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[1,[32,6]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\\n\"],[8,\"cl-animated-if\",[[16,0,[31,[[32,0,[\"styleNamespace\"]],\"__animated-container\"]]]],[[\"@value\",\"@containerClass\"],[[32,0,[\"open\"]],\"cl-flex-col cl-w-full\"]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[18,7,[[30,[36,5],null,[[\"item\"],[[30,[36,4],[\"cl-sidenav/item\"],[[\"class\",\"mini\"],[\"nested\",[32,4]]]]]]]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"is-active\",\"and\",\"or\",\"if\",\"component\",\"hash\"]}",
    "meta": {
      "moduleName": "@coachlogix/cl-ui/components/cl-sidenav/section/template.hbs"
    }
  });
  _exports.default = _default;
});