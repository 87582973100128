define("ember-component-css/instance-initializers/route-styles", ["exports", "ember-component-css/utils/init-route-styles"], function (_exports, _initRouteStyles) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;
  // This file is removed from the build in Ember < 3.6
  function initialize(appInstance) {
    let router = appInstance.lookup('service:router');
    router.on('routeDidChange', function ({
      to
    }) {
      if (likeRouteInfo(to)) {
        (0, _initRouteStyles.default)(appInstance, nestedRouteNames(to));
      }
    });
    router.on('routeWillChange', function ({
      to,
      isActive
    }) {
      if (likeRouteInfo(to)) {
        if (/_loading$/.test(to.name) && isActive) {
          const routeNames = nestedRouteNames(to)
          // loading route names are set with an _loading even though
          // their path is -loading
          .map(name => name.replace(/_loading$/, '-loading'));
          (0, _initRouteStyles.default)(appInstance, routeNames);
        }
      }
    });
  }
  function nestedRouteNames({
    name,
    parent
  }, routeNames = []) {
    routeNames.push(name);
    if (parent) {
      return nestedRouteNames(parent, routeNames);
    }
    return routeNames;
  }
  function likeRouteInfo(info) {
    return info && typeof info === 'object' && info.hasOwnProperty('name');
  }
  var _default = {
    initialize
  };
  _exports.default = _default;
});