define("ember-power-calendar/components/power-calendar-multiple/days", ["exports", "ember-power-calendar/components/power-calendar/days", "ember-power-calendar/utils/computed-fallback-if-undefined", "ember-power-calendar-utils"], function (_exports, _days, _computedFallbackIfUndefined, _emberPowerCalendarUtils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class2, _descriptor;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  let _class = (_dec = (0, _computedFallbackIfUndefined.default)(Infinity), (_class2 = class _class2 extends _days.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "maxLength", _descriptor, this);
    }
    // Methods
    dayIsSelected(date, calendar = this.calendar) {
      let selected = calendar.selected || [];
      return selected.some(d => (0, _emberPowerCalendarUtils.isSame)(date, d, 'day'));
    }
    dayIsDisabled(date) {
      let numSelected = this.calendar.selected && this.calendar.selected.length || 0;
      let maxLength = this.maxLength || Infinity;
      return super.dayIsDisabled(...arguments) || numSelected >= maxLength && !this.dayIsSelected(date);
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "maxLength", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class2));
  _exports.default = _class;
});