define("@coachlogix/cl-ui/components/cl-card/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "Dr/ofYtw",
    "block": "{\"symbols\":[\"@onClick\",\"&attrs\",\"&default\"],\"statements\":[[11,\"div\"],[16,\"role\",[30,[36,0],[[32,1],\"button\"],null]],[16,0,[31,[\"cl-bg-white cl-rounded-lg cl-overflow-auto cl-transform\\n    \",[30,[36,0],[[32,0,[\"raised\"]],\"cl-shadow\"],null],\"\\n    \",[30,[36,0],[[32,1],\"cl-cursor-pointer hover:cl-shadow-clickable hover:cl--translate-y-1\"],null],\"\\n    cl-border-solid cl-border-0\\n    \",[30,[36,0],[[30,[36,1],[[32,0,[\"bordered\"]]],null],\"cl-border cl-border-transparent\"],null],\"\\n    cl-border-\",[32,0,[\"variant\"]],\"\\n    cl-transition-all cl-duration-500 cl-ease-out-exp\"]]],[17,2],[4,[38,2],[\"click\",[32,0,[\"handleClick\"]]],null],[12],[2,\"\\n  \"],[18,3,null],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"if\",\"and\",\"on\"]}",
    "meta": {
      "moduleName": "@coachlogix/cl-ui/components/cl-card/template.hbs"
    }
  });
  _exports.default = _default;
});