define("ember-animated/-private/child", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  class Child {
    constructor(group, id, value, index) {
      this.group = group;
      this.id = id;
      this.value = value;
      this.index = index;
      _defineProperty(this, "state", 'new');
      _defineProperty(this, "removalBlockers", 0);
      _defineProperty(this, "removalCycle", null);
      this.removalBlockers = 0;
      this.removalCycle = null;
    }
    block(cycle) {
      if (this.removalCycle == null || this.removalCycle === cycle) {
        this.removalCycle = cycle;
        this.removalBlockers++;
      }
    }
    unblock(cycle) {
      if (this.removalCycle === cycle) {
        this.removalBlockers--;
      }
    }
    flagForRemoval() {
      this.removalCycle = null;
      this.removalBlockers = 0;
      this.state = 'removing';
    }
    get shouldRemove() {
      return this.state === 'removing' && this.removalBlockers < 1;
    }
    clone() {
      return new Child(this.group, this.id, this.value, this.index);
    }
  }
  _exports.default = Child;
});