define("@coachlogix/cl-ui/components/cl-label/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "6r/nDnN8",
    "block": "{\"symbols\":[\"&default\",\"@text\",\"@class\",\"&attrs\"],\"statements\":[[11,\"div\"],[16,5,[32,0,[\"customStyles\"]]],[16,0,[31,[[32,0,[\"styleNamespace\"]],\"\\n    \",[32,0,[\"variant\"]],\"\\n    cl-uppercase cl-font-bold cl-inline cl-rounded-sm \",[32,3]]]],[17,4],[12],[2,\"\\n\"],[6,[37,0],[[32,2]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[1,[32,2]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[18,1,null],[2,\"\\n\"]],\"parameters\":[]}]]],[13]],\"hasEval\":false,\"upvars\":[\"if\"]}",
    "meta": {
      "moduleName": "@coachlogix/cl-ui/components/cl-label/template.hbs"
    }
  });
  _exports.default = _default;
});