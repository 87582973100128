define("@coachlogix/cl-ui/components/cl-animated-each/component", ["exports", "@ember-decorators/component", "ember-animated/motions/move", "ember-animated/motions/opacity", "@coachlogix/cl-ui/easings/ease-exp-out", "@coachlogix/cl-ui/motions/cl-resize", "@coachlogix/cl-ui/components/cl-animated-each/template"], function (_exports, _component, _move, _opacity, _easeExpOut, _clResize, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _class, _temp;
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  let ClAnimatedEach = (_dec = (0, _component.tagName)(''), _dec2 = (0, _component.layout)(_template.default), _dec(_class = _dec2(_class = (_temp = class ClAnimatedEach extends Ember.Component {
    constructor(...args) {
      super(...args);
      _defineProperty(this, "containerMotion", _clResize.ClResize);
    }
    /* eslint-disable-next-line */
    *transition({
      insertedSprites,
      removedSprites,
      keptSprites
    }) {
      for (let sprite of insertedSprites) {
        (0, _opacity.fadeIn)(sprite, {
          easing: _easeExpOut.default,
          duration: 500
        });
      }
      for (let sprite of removedSprites) {
        (0, _opacity.fadeOut)(sprite, {
          easing: _easeExpOut.default,
          duration: 500
        });
      }

      // do not animate keptSprites when none were added or removed
      // this "default" provides a better interoperability with drag and drop
      // interfaces. This way we skip animating reorders entirely, even the ones
      // caused by draggin and dropping, which is what we want here. Otherwise
      // we would get a weird "double animation effect", one from the drag and drop
      // and another from animated-each.
      if (insertedSprites.length !== 0 || removedSprites.length !== 0) {
        for (let sprite of keptSprites) {
          (0, _move.default)(sprite, {
            easing: _easeExpOut.default,
            duration: 500
          });
        }
      }
    }
  }, _temp)) || _class) || _class);
  var _default = ClAnimatedEach;
  _exports.default = _default;
});