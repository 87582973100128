define("@coachlogix/cl-ui/components/cl-paged-card/page/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "3gohsaBz",
    "block": "{\"symbols\":[\"@class\",\"&attrs\",\"&default\"],\"statements\":[[6,[37,0],[[32,0,[\"shouldRender\"]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[11,\"div\"],[16,0,[32,1]],[17,2],[12],[2,\"\\n    \"],[18,3,null],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"if\"]}",
    "meta": {
      "moduleName": "@coachlogix/cl-ui/components/cl-paged-card/page/template.hbs"
    }
  });
  _exports.default = _default;
});