define("@coachlogix/cl-ui/services/cl-toaster", ["exports", "notyf"], function (_exports, _notyf) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  class ClToaster extends Ember.Service {
    constructor(...args) {
      super(...args);
      _defineProperty(this, "notyf", new _notyf.Notyf({
        duration: 3000,
        types: [{
          type: 'error',
          background: 'var(--danger)',
          icon: {
            text: 'error',
            tagName: 'i',
            className: 'material-icons notyf__icon'
          }
        }, {
          type: 'success',
          background: 'var(--success)',
          icon: {
            text: 'check_circle',
            tagName: 'i',
            className: 'material-icons notyf__icon'
          }
        }, {
          type: 'warn',
          background: 'var(--warn)',
          icon: {
            text: 'warning',
            tagName: 'i',
            className: 'material-icons notyf__icon'
          }
        }, {
          type: 'info',
          background: 'var(--info)',
          icon: {
            text: 'info',
            tagName: 'i',
            className: 'material-icons notyf__icon--info'
          }
        }]
      }));
    }
    error(message, options = {}) {
      return this.notyf.open({
        type: 'error',
        message,
        ...options
      });
    }
    success(message, options = {}) {
      return this.notyf.open({
        type: 'success',
        message,
        ...options
      });
    }
    warn(message, options = {}) {
      return this.notyf.open({
        type: 'warn',
        message,
        ...options
      });
    }
    info(message, options = {}) {
      return this.notyf.open({
        type: 'info',
        message,
        ...options
      });
    }
    dismiss(notification) {
      this.notyf.dismiss(notification);
    }
    dismissAll() {
      this.notyf.dismissAll();
    }
  }
  _exports.default = ClToaster;
});