define("ember-animated/components/animated-value", ["exports", "ember-animated/templates/components/animated-value"], function (_exports, _animatedValue) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class, _class2; // @ts-ignore: templates don't have types
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  /**
    A component that animates when a single value changes.
    Animated-value uses the same arguments as animated-each.
    ```hbs
      <AnimatedContainer>
        {{#animated-value counter rules=rules duration=100 as |v|}}
            <span class="numbers">{{v}}</span>
        {{/animated-value}}
      </AnimatedContainer>
  
      <button {{action "increment"}}>+</button>
      <button {{action "decrement"}}>-</button>
    ```
    ```js
    import Component from '@ember/component';
    import { toLeft, toRight } from 'ember-animated/transitions/move-over';
  
    export default Component.extend({
      rules({ oldItems, newItems }) {
        if (oldItems[0] < newItems[0]) {
          return toLeft;
        } else {
          return toRight;
        }
      },
  
      counter: 10,
  
      actions: {
        increment() {
          this.set('counter', this.get('counter') + 1);
        },
        decrement() {
          this.set('counter', this.get('counter') - 1);
        }
      }
    });
    ```
    @class animated-value
    @public
  */
  let AnimatedValueComponent = (_dec = Ember.computed('value'), (_class = (_class2 = class AnimatedValueComponent extends Ember.Component {
    constructor(...args) {
      super(...args);
      _defineProperty(this, "value", void 0);
      _defineProperty(this, "tagName", '');
      _defineProperty(this, "layout", _animatedValue.default);
    }
    /**
     * The data you are trying to render.
      @argument items
      @type unknown
    */
    get items() {
      return Ember.A([this.value]);
    }
  }, _defineProperty(_class2, "positionalParams", ['value']), _class2), (_applyDecoratedDescriptor(_class.prototype, "items", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "items"), _class.prototype)), _class));
  _exports.default = AnimatedValueComponent;
});