define("@coachlogix/cl-ui/components/cl-color-picker/component", ["exports", "@ember-decorators/component", "@simonwep/pickr", "@coachlogix/cl-ui/components/cl-color-picker/template"], function (_exports, _component, _pickr, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _class, _class2, _descriptor, _temp;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  let ClColorPicker = (_dec = (0, _component.tagName)(''), _dec2 = (0, _component.layout)(_template.default), _dec3 = Ember.inject.service, _dec4 = Ember._action, _dec5 = Ember._action, _dec6 = Ember._action, _dec7 = Ember._action, _dec(_class = _dec2(_class = (_class2 = (_temp = class ClColorPicker extends Ember.Component {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "intl", _descriptor, this);
      _defineProperty(this, "default", '');
      _defineProperty(this, "default", '#ffffff');
    }
    setupPickr(element) {
      let el = element.querySelector('input') || element;
      this._pickr = _pickr.default.create({
        el,
        useAsButton: true,
        container: '#cl-wormhole',
        theme: 'classic',
        default: this.value || this.default,
        lockOpacity: true,
        position: 'bottom-end',
        appClass: this.styleNamespace,
        disabled: this.disabled,
        components: {
          preview: true,
          opacity: false,
          hue: true,
          interaction: {
            hex: true,
            rgba: true,
            hsva: true,
            input: true,
            clear: true,
            save: true
          }
        },
        i18n: {
          'btn:save': this.intl.t('cl-color-picker.save'),
          'btn:cancel': this.intl.t('cl-color-picker.cancel'),
          'btn:clear': this.intl.t('cl-color-picker.clear')
        }
      });
      this._pickr.on('save', hsva => {
        this.onChange(this.formatColor(hsva));
        this._pickr.hide();
      });
    }
    destroyPickr() {
      this._pickr.destroyAndRemove();
    }
    updateColor([value]) {
      if (this._pickr) {
        let currentColor = this.formatColor(this._pickr.getColor());
        // This check is to avoid setting the same color twice one after another
        // Without this check, this will result in two computations for every color change
        if (currentColor !== value) {
          this._pickr.setColor(value, true);
          this._pickr.applyColor(true);
        }
      }
      return value;
    }
    formatColor(hsva) {
      if (!hsva) {
        return null;
      }
      return hsva.toHEXA().toString();
    }
    updateDisabled([value]) {
      if (this._pickr) {
        if (value) {
          this._pickr.disable();
        } else {
          this._pickr.enable();
        }
      }
    }
  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "intl", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "setupPickr", [_dec4], Object.getOwnPropertyDescriptor(_class2.prototype, "setupPickr"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "destroyPickr", [_dec5], Object.getOwnPropertyDescriptor(_class2.prototype, "destroyPickr"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "updateColor", [_dec6], Object.getOwnPropertyDescriptor(_class2.prototype, "updateColor"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "updateDisabled", [_dec7], Object.getOwnPropertyDescriptor(_class2.prototype, "updateDisabled"), _class2.prototype)), _class2)) || _class) || _class);
  var _default = ClColorPicker;
  _exports.default = _default;
});