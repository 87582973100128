define("ember-yeti-table/-private/utils/filtering-utils", ["exports", "ember-yeti-table/-private/utils/create-regex"], function (_exports, _createRegex) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = filterData;
  function createColumnFilters(columns) {
    let searcheableColumns = columns.filter(c => {
      return !Ember.isEmpty(Ember.get(c, 'filter')) || !Ember.isEmpty(Ember.get(c, 'filterFunction'));
    });
    return searcheableColumns.map(c => {
      let regex = (0, _createRegex.default)(Ember.get(c, 'filter'));
      return row => {
        let value = Ember.get(row, Ember.get(c, 'prop'));
        let passesRegex = true;
        if (!Ember.isEmpty(Ember.get(c, 'filter'))) {
          passesRegex = regex.test(value);
        }
        let passesCustom = true;
        if (!Ember.isEmpty(Ember.get(c, 'filterFunction'))) {
          passesCustom = Ember.get(c, 'filterFunction')(value, Ember.get(c, 'filterUsing'));
        }
        return passesRegex && passesCustom;
      };
    });
  }
  function filterData(data, columns, globalFilter, filterFunction, filterUsing) {
    if (Ember.isEmpty(data)) {
      return [];
    }
    if (Ember.isEmpty(columns)) {
      // bail out if there are no columns to filter
      return data;
    }
    let globalRegex = (0, _createRegex.default)(globalFilter, false, true, true);
    let columnFilters = createColumnFilters(columns);
    return data.filter(row => {
      let passesGeneral = true;
      if (!Ember.isEmpty(globalRegex)) {
        passesGeneral = columns.some(c => {
          return globalRegex.test(Ember.get(row, Ember.get(c, 'prop')));
        });
      }
      let passesColumn = true;
      if (!Ember.isEmpty(columnFilters)) {
        passesColumn = columnFilters.every(fn => fn(row));
      }
      let passesCustom = true;
      if (!Ember.isEmpty(filterFunction)) {
        passesColumn = filterFunction(row, filterUsing);
      }
      return passesGeneral && passesColumn && passesCustom;
    });
  }
});