define("@coachlogix/cl-ui/motions/cl-resize", ["exports", "ember-animated/motions/resize", "@coachlogix/cl-ui/easings/ease-exp-out"], function (_exports, _resize, _easeExpOut) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.ClResize = void 0;
  _exports.default = clResize;
  function clResize(sprite, opts) {
    return new ClResize(sprite, opts).run();
  }
  class ClResize extends _resize.Resize {
    constructor(sprite, opts) {
      opts.easing = _easeExpOut.default;
      opts.duration = 500;
      super(sprite, opts);
    }
  }
  _exports.ClResize = ClResize;
});