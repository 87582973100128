define("@coachlogix/cl-ui/services/places", ["exports", "ember-concurrency-decorators", "load-script2"], function (_exports, _emberConcurrencyDecorators, _loadScript) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _temp;
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  const DEFAULT_TYPES = ['(cities)'];
  const DEFAULT_FIELDS = ['geometry.location', 'formatted_address', 'address_components', 'url', 'name', 'place_id'];
  let PlacesService = (_class = (_temp = class PlacesService extends Ember.Service {
    constructor(...args) {
      super(...args);
      _defineProperty(this, "googlePlaces", null);
      _defineProperty(this, "autocompleteService", null);
      _defineProperty(this, "placesService", null);
    }
    *initPlaces() {
      if (this.googlePlaces) {
        return this.googlePlaces;
      }
      const config = Ember.getOwner(this).resolveRegistration('config:environment');
      (true && !(Ember.isPresent(config['google-places-api']) && Ember.isPresent(config['google-places-api'].key)) && Ember.assert('A "key" property must be specified in a config["google-places-api"] object on environemnt', Ember.isPresent(config['google-places-api']) && Ember.isPresent(config['google-places-api'].key)));
      yield (0, _loadScript.default)(`https://maps.googleapis.com/maps/api/js?key=${config['google-places-api'].key}&libraries=places&language=en`);
      if (window?.google) {
        let googlePlaces = window.google.maps.places;
        let autocompleteService = new googlePlaces.AutocompleteService();
        let placesService = new googlePlaces.PlacesService(document.createElement('div'));
        let sessionToken = new googlePlaces.AutocompleteSessionToken();
        this.googlePlaces = googlePlaces;
        this.autocompleteService = autocompleteService;
        this.sessionToken = sessionToken;
        this.placesService = placesService;
      } else {
        (true && Ember.warn('there was some error loading the google places library', {
          id: 'google-places.loading-error'
        }));
      }
    }
    *getSessionToken() {
      yield this.initPlaces.perform();
      return new this.googlePlaces.AutocompleteSessionToken();
    }

    /**
     * This task takes a request object and returns an array of places
     *
     * @param {AutocompletionRequest} request https://developers.google.com/maps/documentation/javascript/reference/places-autocomplete-service#AutocompletionRequest
     */
    *getPlacePredictions(request) {
      if (Ember.isBlank(request.input)) {
        return [];
      }
      yield this.initPlaces.perform();
      (true && !(Ember.isPresent(request.input)) && Ember.assert('input property was not passed inside properties object param', Ember.isPresent(request.input)));
      if (!request.types) {
        request.types = DEFAULT_TYPES;
      }
      return new Promise((resolve, reject) => {
        this.autocompleteService.getPlacePredictions(request, (result, status) => {
          if (status !== this.googlePlaces.PlacesServiceStatus.OK) {
            reject(status);
          }
          resolve(result);
        });
      });
    }

    /**
     * This task takes a request object and returns an array of places
     *
     * @param {PlaceDetailsRequest} request https://developers.google.com/maps/documentation/javascript/reference/places-service#PlaceDetailsRequest
     */
    *getDetails(request) {
      yield this.initPlaces.perform();
      (true && !(Ember.isPresent(request.placeId)) && Ember.assert('getDetails needs the placeId and fields as properties of the request params', Ember.isPresent(request.placeId)));
      if (!request.fields) {
        request.fields = DEFAULT_FIELDS;
      }
      return new Promise((resolve, reject) => {
        this.placesService.getDetails(request, (result, status) => {
          if (status !== this.googlePlaces.PlacesServiceStatus.OK) {
            reject(status);
          }
          resolve(result);
        });
      });
    }
  }, _temp), (_applyDecoratedDescriptor(_class.prototype, "initPlaces", [_emberConcurrencyDecorators.enqueueTask], Object.getOwnPropertyDescriptor(_class.prototype, "initPlaces"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "getSessionToken", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "getSessionToken"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "getPlacePredictions", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "getPlacePredictions"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "getDetails", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "getDetails"), _class.prototype)), _class);
  _exports.default = PlacesService;
});