define("ember-concurrency/helpers/cancel-all", ["exports", "ember-concurrency/-helpers"], function (_exports, _helpers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.cancelHelper = cancelHelper;
  _exports.default = void 0;
  const CANCEL_REASON = "the 'cancel-all' template helper was invoked";
  function cancelHelper(args) {
    let cancelable = args[0];
    if (!cancelable || typeof cancelable.cancelAll !== 'function') {
      (true && !(false) && Ember.assert(`The first argument passed to the \`cancel-all\` helper should be a Task or TaskGroup (without quotes); you passed ${cancelable}`, false));
    }
    return (0, _helpers.taskHelperClosure)('cancel-all', 'cancelAll', [cancelable, {
      reason: CANCEL_REASON
    }]);
  }
  var _default = Ember.Helper.helper(cancelHelper);
  _exports.default = _default;
});