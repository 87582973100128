define("@coachlogix/cl-ui/components/cl-radio-group/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "AHW2Jpnr",
    "block": "{\"symbols\":[\"&attrs\",\"@groupValue\",\"&default\"],\"statements\":[[11,\"div\"],[16,0,[31,[[32,0,[\"styleNamespace\"]],\" \",[30,[36,0],[[32,0,[\"disabled\"]],\"disabled\"],null]]]],[24,\"role\",\"radiogroup\"],[24,\"tabindex\",\"0\"],[17,1],[4,[38,1],[\"keydown\",[32,0,[\"handleKeydown\"]]],null],[12],[2,\"\\n  \"],[18,3,[[30,[36,3],null,[[\"radio\"],[[30,[36,2],[\"cl-radio\"],[[\"disabled\",\"groupValue\",\"onChange\",\"registerRadio\",\"unregisterRadio\"],[[32,0,[\"disabled\"]],[32,2],[32,0,[\"onChange\"]],[32,0,[\"registerRadio\"]],[32,0,[\"unregisterRadio\"]]]]]]]]]],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"if\",\"on\",\"component\",\"hash\"]}",
    "meta": {
      "moduleName": "@coachlogix/cl-ui/components/cl-radio-group/template.hbs"
    }
  });
  _exports.default = _default;
});