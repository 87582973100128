define("@coachlogix/cl-ui/components/cl-dot-pager/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "Gpug8Xli",
    "block": "{\"symbols\":[\"p\"],\"statements\":[[10,\"div\"],[14,0,\"cl-flex-1 cl-flex\"],[12],[2,\"\\n\"],[6,[37,3],[[30,[36,2],[[30,[36,2],[[32,0,[\"pagesArray\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[15,0,[31,[\"dot \",[30,[36,1],[[30,[36,0],[[32,0,[\"page\"]],[32,1]],null],\"active\"],null]]]],[12],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[13],[2,\"\\n\"],[10,\"div\"],[12],[2,\"\\n  \"],[8,\"cl-button\",[[16,\"disabled\",[32,0,[\"isFirstPage\"]]],[24,0,\"cl-mr-1\"]],[[\"@bordered\",\"@iconButton\",\"@onClick\"],[true,true,[32,0,[\"previousPage\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"cl-icon\",[],[[\"@icon\"],[\"chevron-left\"]],null],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n  \"],[8,\"cl-button\",[[16,\"disabled\",[32,0,[\"isLastPage\"]]]],[[\"@bordered\",\"@iconButton\",\"@onClick\"],[true,true,[32,0,[\"nextPage\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"cl-icon\",[],[[\"@icon\"],[\"chevron-right\"]],null],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"eq\",\"if\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "@coachlogix/cl-ui/components/cl-dot-pager/template.hbs"
    }
  });
  _exports.default = _default;
});