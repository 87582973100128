define("@coachlogix/cl-ui/components/cl-input/component", ["exports", "@ember-decorators/component", "autosize", "@coachlogix/cl-ui/components/cl-input/template"], function (_exports, _component, _autosize, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _class, _class2, _temp;
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  let ClInput = (_dec = (0, _component.tagName)(''), _dec2 = (0, _component.layout)(_template.default), _dec3 = Ember.computed('solid', 'line'), _dec4 = Ember._action, _dec(_class = _dec2(_class = (_class2 = (_temp = class ClInput extends Ember.Component {
    constructor(...args) {
      super(...args);
      _defineProperty(this, "uniqueId", `cl-input-${Ember.guidFor(this)}`);
      _defineProperty(this, "editable", true);
    }
    get inputStyleClass() {
      if (this.solid) {
        return 'solid';
      } else if (this.line) {
        return 'line';
      } else {
        return 'bordered';
      }
    }
    setupAutosize(el) {
      (0, _autosize.default)(el);
    }
    handleInput(ev) {
      if (this.onChange) {
        let value = ev.target.value || '';
        let disallowedChars = this.disallowedChars || '';
        for (let i = 0; i < disallowedChars.length; i++) {
          let char = disallowedChars.charAt(i);
          value = replaceAll(value, char, '');
        }
        this.onChange(value);
        Ember.run.schedule('afterRender', this, '_syncValue', ev.target);
      }
    }
    _syncValue(el) {
      if (this.isDestroyed || this.isDestroying) {
        return;
      }
      let actualValue = this.value;
      let renderedValue = el.value;
      if (!Ember.isNone(actualValue) && !Ember.isNone(renderedValue) && actualValue.toString() !== renderedValue.toString()) {
        let start;
        let end;

        // guards because only text, search, url, tel and password support this
        try {
          start = el.selectionStart;
          end = el.selectionEnd;
        } catch (e) {
          // no-op
        }
        el.value = actualValue;
        try {
          el.setSelectionRange(start, end);
        } catch (e) {
          // no-op
        }
      }
    }
  }, _temp), (_applyDecoratedDescriptor(_class2.prototype, "inputStyleClass", [_dec3], Object.getOwnPropertyDescriptor(_class2.prototype, "inputStyleClass"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "handleInput", [_dec4], Object.getOwnPropertyDescriptor(_class2.prototype, "handleInput"), _class2.prototype)), _class2)) || _class) || _class);
  /**
   * escape a given string to give a safe regexp ready string
   * @param {string} string the regexp string that will be escaped to build a safe regexp
   */
  function escapeRegExp(string) {
    return string.replace(/[.*+\-?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
  }

  /**
   * replaces all occurrences of `find` in `str` with `replace`
   * @param {string} str the string to replace in
   * @param {string} find the string to replace
   * @param {string} replace the string to replace with
   */
  function replaceAll(str, find, replace) {
    return str.replace(new RegExp(escapeRegExp(find), 'g'), replace);
  }
  var _default = ClInput;
  _exports.default = _default;
});