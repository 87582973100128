define("@coachlogix/cl-ui/components/cl-table/component", ["exports", "@ember-decorators/component", "@coachlogix/cl-ui/components/cl-table/template"], function (_exports, _component, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _class, _class2, _temp;
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  const hasStorage = function () {
    try {
      let test = 'test';
      localStorage.setItem(test, test);
      localStorage.removeItem(test);
      return true;
    } catch (exception) {
      return false;
    }
  }();
  let ClTable = (_dec = (0, _component.tagName)(''), _dec2 = (0, _component.layout)(_template.default), _dec3 = Ember.computed('clConfigKey', 'configKey'), _dec4 = Ember._action, _dec5 = Ember._action, _dec6 = Ember._action, _dec7 = Ember._action, _dec8 = Ember._action, _dec9 = Ember._action, _dec(_class = _dec2(_class = (_class2 = (_temp = class ClTable extends Ember.Component {
    constructor(...args) {
      super(...args);
      _defineProperty(this, "pagination", false);
      _defineProperty(this, "pageSize", 10);
      _defineProperty(this, "filter", '');
      _defineProperty(this, "showControls", true);
      _defineProperty(this, "showExport", true);
      _defineProperty(this, "showColumns", true);
      _defineProperty(this, "pageSizes", [10, 15, 20, 25]);
      _defineProperty(this, "index", 0);
      _defineProperty(this, "defaultVisibleColumns", []);
    }
    get clConfigKey() {
      return `cl-ui:cl-table:config:${this.configKey}`;
    }
    get columnsConfig() {
      if (hasStorage && this.configKey) {
        let configString = localStorage.getItem(this.clConfigKey);
        if (configString) {
          return JSON.parse(configString);
        }
      }
      return null;
    }
    isColumnVisible(c) {
      // save the column's default visibility config
      // before potentially initializing it to the localStorage value
      if (c.visible) {
        this.defaultVisibleColumns.push(c);
      }
      let config = this.columnsConfig;
      if (config) {
        let isVisible = config.includes(this.index);
        this.index++;
        return isVisible;
      }

      // return column default visibility by default
      return c.visible;
    }
    selectColumns(columns, visibleColumns) {
      for (let c of columns) {
        let isVisible = visibleColumns.includes(c);
        Ember.set(c, 'visible', isVisible);
      }
      if (hasStorage && this.configKey) {
        let config = visibleColumns.map(c => columns.indexOf(c));
        localStorage.setItem(this.clConfigKey, JSON.stringify(config));
      }
    }
    resetVisibleColumns(columns) {
      let visibleColumns = this.defaultVisibleColumns;
      for (let c of columns) {
        let isVisible = visibleColumns.includes(c);
        Ember.set(c, 'visible', isVisible);
      }
      if (hasStorage && this.configKey) {
        localStorage.removeItem(this.clConfigKey);
      }
      this.set('showColumnSelection', false);
    }
    maybeResetVisibleColumns(columns = []) {
      let noVisibleColumns = columns.every(c => !c.visible);

      // if there are no visible columns, then that means the config must be invalid
      if (columns && columns.length && noVisibleColumns) {
        this.resetVisibleColumns(columns);
      }
    }
    generateVisibleColumnsParam(visibleColumns = []) {
      let columnsValue = visibleColumns.map(c => c.prop).filter(Boolean).join(',');
      return {
        columns: columnsValue
      };
    }
    generateExportLink(type, baseUrl, visibleColumns = []) {
      let {
        columns
      } = this.generateVisibleColumnsParam(visibleColumns);
      if (baseUrl.includes('?')) {
        return `${baseUrl}&columns=${columns}`;
      } else {
        return `${baseUrl}?columns=${columns}`;
      }
    }
  }, _temp), (_applyDecoratedDescriptor(_class2.prototype, "columnsConfig", [_dec3], Object.getOwnPropertyDescriptor(_class2.prototype, "columnsConfig"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "isColumnVisible", [_dec4], Object.getOwnPropertyDescriptor(_class2.prototype, "isColumnVisible"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "selectColumns", [_dec5], Object.getOwnPropertyDescriptor(_class2.prototype, "selectColumns"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "resetVisibleColumns", [_dec6], Object.getOwnPropertyDescriptor(_class2.prototype, "resetVisibleColumns"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "maybeResetVisibleColumns", [_dec7], Object.getOwnPropertyDescriptor(_class2.prototype, "maybeResetVisibleColumns"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "generateVisibleColumnsParam", [_dec8], Object.getOwnPropertyDescriptor(_class2.prototype, "generateVisibleColumnsParam"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "generateExportLink", [_dec9], Object.getOwnPropertyDescriptor(_class2.prototype, "generateExportLink"), _class2.prototype)), _class2)) || _class) || _class);
  var _default = ClTable;
  _exports.default = _default;
});