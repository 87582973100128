define("@coachlogix/cl-ui/components/cl-expandable/body/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "8otzdPHJ",
    "block": "{\"symbols\":[\"@expanded\",\"@containerClass\",\"&attrs\",\"&default\"],\"statements\":[[8,\"cl-animated-if\",[[17,3]],[[\"@value\",\"@containerClass\"],[[32,1],[32,2]]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[18,4,null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "@coachlogix/cl-ui/components/cl-expandable/body/template.hbs"
    }
  });
  _exports.default = _default;
});