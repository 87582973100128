define("@coachlogix/cl-ui/helpers/cl-ui-is-selected", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.isSelected = isSelected;
  // TODO: Make it private or scoped to the component
  function isSelected([option, selected] /* , hash*/) {
    if (selected === undefined || selected === null) {
      return false;
    }
    if (Ember.isArray(selected)) {
      for (let i = 0; i < selected.length; i++) {
        let selectedItem = selected.objectAt ? selected.objectAt(i) : selected[i];
        if (Ember.isEqual(selectedItem, option)) {
          return true;
        }
      }
      return false;
    } else {
      return Ember.isEqual(option, selected);
    }
  }
  var _default = Ember.Helper.helper(isSelected);
  _exports.default = _default;
});